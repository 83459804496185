import { useMutation, useQuery } from "@apollo/client";
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import { useHistory, useParams } from "react-router-dom";
import { useState, useEffect, useContext, useCallback } from "react";
import { getDateDay, getDateYear } from "../helpers/Payroll/Payroll";
import styles from "../../src/components/Payroll/PayrollStyles.module.css"
import { 
        GET_PAYROLLCOLLABORATOR_TIMBRADO, 
        GET_PAYROLL, 
        UPDATE_PAYROLL_STATE,
        UPDATE_PAYROLL_COLLABORATOR_TOKENTIMBRE,
        UPDATE_PAYROLL_COLLABORATOR_TOKENTIMBRE_DEMO,
        UPDATE_PAYROLL_COLLABORATOR_XML,
        GET_PAYROLLCOLLABORATOR_TIMBRADO_EXCELL,
        GET_ALL_PAYROLL,
        GET_PAYROLLCOLLABORATOR,
        GET_INCIDENT_PAYROLL,
        GET_ALL_COMPANY,
        UPDATE_PAYROLL_COLLABORATOR_TOKENTIMBRE_TIMBRAR_AGAIN,
        GET_PAYROLLCOLLABORATOR_CANCEL_TIMBRE,
        SEND_EMAIL_TIMBRADO_INIT
        //SEND_EMAIL_TIMBRADO_INIT_UAT
      } from "../Querys/querys";
import CustomTabs from '../components/Collaborators/Tab/CustomTabs';
import CustomTab from '../components/Collaborators/Tab/CustomTabMain';
import {formatter} from "../helpers/formatoMoneda"
import { v4 as uuid } from "uuid";
import {
  postTimbrarNomina
} from "../services/cfdiService";
import {
  postPDFReciboAWS,
  postXMLReciboAWSDate,
  postFilesPDFPayrolldate
} from "../services/candidateService";
import { WarningAlertTimbre } from "../alerts/WarningAlertTimbre";
import descargaXml from "../assets/svg/icono_descarga_xml.svg";
import CheckPermission from "../helpers/Administradores/Permissions";
import { server } from "../global/server";
import prefactura from "../assets/svg/icono_prefactura.svg";
import iconoPDF from "../assets/svg/icono_descargar_pdf.svg";
import iconoPDFAnterior from "../assets/svg/Alert_Icon.svg";
import {
  postMailPayroll,
  postMailPayrollUAT
} from "../services/candidateService";
import PayrollProcessContext from "../context/PayrollProcess/PayrollProcessContext";
import { openFinish } from "../context/PayrollProcess/Actions";
import ShowTerminar from "../components/Payroll/Modals/ShowTerminar/ShowTerminar"
import { CreateExcelFilePayrollPNT } from "../components/Payroll/Modals/ExcelPayrollPNT";
import { AttentionAlert2 } from "../alerts/attentionAlert2";
import LockedSystem from "../alerts/LockedSystem";
import { AdminContext } from "../context/AdminContext/AdminContext";
import { LoadingAlert } from "../alerts/loadingAlerts";
import moment from "moment";
import axios from "axios";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const PayrollTimbrar = (props: TabPanelProps) => {
  const {dispatch} = useContext(PayrollProcessContext)
  const { id, tab } = useParams<any>();
  const [filtrado, setFiltrado] =  useState<any[]>([])
  const [payrollCancel, setPayrollCancel] =  useState<any[]>([])
  const [isCheck, setIsCheck] =  useState<any[]>([])
  const [timbrar, setTimbrar] =  useState(true)
  const history = useHistory();
  const {adminState} = useContext(AdminContext)
  const {loading, data} = useQuery(GET_PAYROLL,{
    variables:{getPayrollId: id}
  })
  const [sendEmail] = useMutation(SEND_EMAIL_TIMBRADO_INIT)
  const PayrollData = data?.GET_PAYROLL
  const [year , setYear] = useState<any>()
  useEffect(() => {
    if(PayrollData){
      setYear(moment(PayrollData?.init_date).format("YYYY"))
    }
  }, [PayrollData]);

  const resultPayrollTimbrado = useQuery(GET_PAYROLLCOLLABORATOR_TIMBRADO, {
    variables: { getPayrollcollaboratorTimbradoId: id },
  });

  const resultPayrollTimbradoExcell = useQuery(GET_PAYROLLCOLLABORATOR_TIMBRADO_EXCELL, {
    variables: { getPayrollcollaboratorTimbradoExcellId: id },
  });

  const allPayrollTimbradoExcell = resultPayrollTimbradoExcell.data?.GET_PAYROLLCOLLABORATOR_TIMBRADO_EXCELL;


  const allPayrollTimbrado = resultPayrollTimbrado.data?.GET_PAYROLLCOLLABORATOR_TIMBRADO;

  const handleChange = () =>{
    console.log(1)
  }

  /*--------------------------sistemaActivo--------------------*/
  const { data: resultCompany, startPolling: startPollingSistemaActivo, stopPolling: stopPollingSistemaActivo } = useQuery(GET_ALL_COMPANY);
  const myCompany = resultCompany?.GET_ALL_COMPANY;
  const [sistemaActivo, setSistemaActivo] = useState<any>(true);
  useEffect(()=>{
    startPollingSistemaActivo(1000);
    return () =>{
      stopPollingSistemaActivo()
    }
  },[startPollingSistemaActivo,stopPollingSistemaActivo])

  useEffect(() => {
    if(myCompany){
      setSistemaActivo(myCompany[0]?.SistemaActivo)
    }
  }, [myCompany]);
  /*--------------------------sistemaActivo--------------------*/

  const obtenerDatos = useCallback(async () => {
    await setFiltrado(
      allPayrollTimbrado?.filter((item:any) => item.dispersionStatus === "LIQUIDADO")
      )
    setPayrollCancel(
      allPayrollTimbrado?.filter((item:any) => item.AcuseCancelacion || (item.PDF_back && item.PDF_back !== ""))
    )
  }, [allPayrollTimbrado]);

  useEffect(() => {
    obtenerDatos();
  }, [allPayrollTimbrado, obtenerDatos]);

  const datosFiltrados = (e:any) =>{
    if(e !== '') {
      let expresion = new RegExp(`${e}.*`, "i")
      const nuevoFiltrado = filtrado.filter((lis:any) => expresion.test(lis.colaborator))
      setFiltrado(nuevoFiltrado)
    }else{
      obtenerDatos()
    }
  }



  
  const [updatePayroll] = useMutation(UPDATE_PAYROLL_STATE);

  const updateState = (idPayroll:any) =>{

    const payronTimbrados = filtrado?.filter((item:any) => item.UUID !== null)
    const payronNoTimbrados = filtrado?.filter((item:any) => item.UUID == null)

    if(payronNoTimbrados?.length === 0){
      openFinish({idPayroll}, dispatch)
    }
    else{

     
      AttentionAlert2({
                        text:"Se timbraron "+ payronTimbrados?.length + " nominas, faltan "+ payronNoTimbrados?.length + " nominas por timbrar"
                      });
    }

  }

  //const [updateTokenTimbre] = useMutation(UPDATE_PAYROLL_COLLABORATOR_TOKENTIMBRE);
  const [updateTokenTimbre] = useMutation(UPDATE_PAYROLL_COLLABORATOR_TOKENTIMBRE_DEMO);
  const [updateTokenTimbreAgain] = useMutation(UPDATE_PAYROLL_COLLABORATOR_TOKENTIMBRE_TIMBRAR_AGAIN);

  const [updateXML] = useMutation(UPDATE_PAYROLL_COLLABORATOR_XML,
    {
      refetchQueries: [
        {query:GET_PAYROLLCOLLABORATOR_TIMBRADO, variables:{getPayrollcollaboratorTimbradoId: id}},
        {query:GET_PAYROLL, variables:{getPayrollId: id}},
        {query: GET_ALL_PAYROLL },
        {query: GET_PAYROLLCOLLABORATOR, variables:{getPayrollcollaboratorId: id}},
        {query: GET_INCIDENT_PAYROLL, variables:{idPayroll: parseInt(id)}},
        {query:GET_PAYROLLCOLLABORATOR_CANCEL_TIMBRE, variables:{getPayrollcollaboratorTimbradoId: id}},
        {query:GET_PAYROLLCOLLABORATOR_TIMBRADO_EXCELL, variables:{getPayrollcollaboratorTimbradoExcellId: id}},
        ],
    });

    const updateStateNomine = (id: any) => {
      if(!PayrollData?.dateFinish ){
        updatePayroll({
          variables: {
            input: {
              id: id,
              statusProgress: "Terminar",
            },
          },
        });
      } 
    };

  const handleOnExport = () => {

    CreateExcelFilePayrollPNT(allPayrollTimbradoExcell);
  
  }

  const handleSelectAll = (e:any) =>{
  
    if(!e.target.checked){
      setIsCheck([])
    }

    let filtradoSeleccionado = (filtrado.filter((lis:any)=>{
      return e.target.checked && !isCheck.includes(lis.id) && (
                                                                (!lis.UUID || lis.UUID === "") 
                                                                || 
                                                                ( (lis.AcuseCancelacion && lis.AcuseCancelacion !== "") && (lis.UUID && lis.UUID !== "") )
                                                                ||
                                                                ( 
                                                                  (!lis.AcuseCancelacion || lis.AcuseCancelacion === "") 
                                                                  && 
                                                                  (!lis.UUID || lis.UUID === "") 
                                                                  &&
                                                                  (lis.PDF_back && lis.PDF_back !== "")
                                                                )
                                                              )
    }).map((lis:any) =>(lis.id)))

    setIsCheck(filtradoSeleccionado)
  }

  const handleClick =  (e:any) => {
    
    const {id, checked} = e.target
    setIsCheck([...isCheck, id])
    if(!checked){
      setIsCheck(isCheck.filter((lis:any) => lis !== id))
    }

  }

  // const timbrarNomina = async () =>{
  //   setTimbrar(false)
  //   let tokenTimbre = uuid();
  //   if(isCheck.length > 0){
  //     LoadingAlert({ title: "Cargando..." });
  //     await sendEmail({
  //       variables:{
  //        input: {
  //          id:isCheck,
  //          idAdmin:adminState?.id,
  //          nameAdmin:adminState?.Nombre,
  //          emailAdmin:adminState?.Usuario,
  //          nameNomina:tab,
  //          periodo:`${getDateDay(PayrollData?.init_date)}-${getDateDay(PayrollData?.end_date)} ${getDateYear(PayrollData?.end_date)}`,
  //          type:"inicioTimbrado"
  //        }
  //       }
  //     }) 
  //     for (const payrollColaboration of isCheck){
  //       await updateTokenTimbre({
  //         variables: {
  //           id: String(payrollColaboration),
  //           tokenTimbre: tokenTimbre
  //         },
  //       })
  //     }
  //     if(tokenTimbre !== undefined){
  //       if( String(tokenTimbre) !== "" ){
  //         const restultSELLO = await postTimbrarNomina(tokenTimbre);
  //         let text = ""
  //         let textTimbre = ""
  //        if(restultSELLO !== undefined){
  //          for (const sello of restultSELLO){
  //             if (sello.MensajeIncidencia === "" && String(sello.UUID) !== "" ){
  //               const restultfile = await postXMLReciboAWSDate(sello.Folio, sello.xml, sello.Colaborador, sello.Fecha);
  //               const pdfLink = await postPDFReciboAWS(sello.Folio, sello.Colaborador, sello.Fecha);
  //               await updateXML
  //               ({
  //                 variables: {
  //                   id:sello.Folio,
  //                   input: {
  //                     xml: restultfile,
  //                     LinkPDF: pdfLink
  //                   },
  //                 },
  //               }).then((result) => {
  //                 postMailPayroll(sello.Folio)
  //               })
  //               textTimbre = textTimbre +"<b>Documento timbrado:</b><br><b>"+sello.Colaborador+":</b> "+ sello.UUID+"<br>"
  //               }else{
  //               const MensajeIncidencia = String(sello.MensajeIncidencia) === ""? "No se recibió respuesta del servicio, no se ha timbrado el documento, favor de volverlo a intentar.": sello.MensajeIncidencia
  //               text = text +"<b>Problema Colaborador:</b><br><b>"+sello.Colaborador+":</b> "+ sello.CodigoError+" "+ MensajeIncidencia + "<br>"
  //               await sendEmail({
  //                 variables:{
  //                  input: {
  //                    id:isCheck,
  //                    idAdmin:adminState?.id,
  //                    nameAdmin:adminState?.Nombre,
  //                    emailAdmin:adminState?.Usuario,
  //                    nameNomina:tab,
  //                    periodo:`${getDateDay(PayrollData?.init_date)}-${getDateDay(PayrollData?.end_date)} ${getDateYear(PayrollData?.end_date)}`,
  //                    type:"errorTimbrado"
  //                  }
  //                 }
  //               })
  //             }
  //           }
  //           if(text !== "" || textTimbre !== ""){
  //             WarningAlertTimbre({
  //               text: textTimbre+text,
  //             });
   
  //           }
  //         }
  //         setTimbrar(true)
  //         await sendEmail({
  //           variables:{
  //            input: {
  //              id:isCheck,
  //              idAdmin:adminState?.id,
  //              nameAdmin:adminState?.Nombre,
  //              emailAdmin:adminState?.Usuario,
  //              nameNomina:tab,
  //              periodo:`${getDateDay(PayrollData?.init_date)}-${getDateDay(PayrollData?.end_date)} ${getDateYear(PayrollData?.end_date)}`,
  //              type:"FinTimbrado"
  //            }
  //           }
  //         })
  //         setIsCheck([])
  //       }
  //     }
  //   }      
  // }

  const timbrarNomina = async () =>{
    let iTimbre: any = 1;
     setTimbrar(false)    
     let tokenTimbre = uuid();      
     if(isCheck.length > 0){
      const restultSELLO: any[] = [];
      LoadingAlert({ title: "Cargando..." });
      await sendEmail({
        variables:{
         input: {
           id:isCheck,
           idAdmin:adminState?.id,
           nameAdmin:adminState?.Nombre,
           emailAdmin:adminState?.Usuario,
           nameNomina:tab,
           periodo:`${getDateDay(PayrollData?.init_date)}-${getDateDay(PayrollData?.end_date)} ${getDateYear(PayrollData?.end_date)}`,
           type:"inicioTimbrado"
         }
        }
      }) 
      for (const payrollColaboration of isCheck){
        await updateTokenTimbre({
          variables: {
            id: String(payrollColaboration),
            tokenTimbre: tokenTimbre,
            iTimbre: iTimbre,
          },
        }).then((res:any) => {
          //console.log('res',res)          
          restultSELLO.push(res.data.UPDATE_PAYROLL_COLLABORATOR_TOKENTIMBRE_DEMO)
        })  
        iTimbre++;
      }      
      if(tokenTimbre !== undefined){
        //debugger
        if( tokenTimbre != "" ){
          //const restultSELLO = await postTimbrarNomina(tokenTimbre);
          let text = ""
          let textTimbre = ""
          //console.log( restultSELLO)          
          if(restultSELLO !== undefined){
            //debugger
            for (const sello of restultSELLO){
              if ((sello.MensajeIncidencia === "" || sello?.MensajeIncidencia == undefined) && sello.UUID != "" ){
                //const restultfile = await postXMLReciboAWSDate(sello.Folio, sello.xml, sello.Colaborador, sello.Fecha);
                const restultfile = sello.xml
                const pdfB = await postPDFReciboAWS(sello.Folio);
                const pdfLink = await postFilesPDFPayrolldate(sello.Folio, pdfB, sello.Colaborador, sello.Fecha)
  
                await updateXML
                ({
                  variables: {
                    id:sello.Folio,
                    input: {
                      xml: restultfile,
                      LinkPDF: pdfLink
                    },
                  },
                }).then((result) => {
                  //console.log('result',result)
                  textTimbre = textTimbre +"<b>Documento timbrado:</b><br><b>"+sello.Colaborador+":</b> "+ sello.UUID+"<br>"
                //  postMailPayroll(sello.Folio)
                 postMailPayrollUAT(sello.Folio)
                })               
              }else{
                const MensajeIncidencia = sello.MensajeIncidencia == ""? "No se timbrar el documento": sello.MensajeIncidencia
                text = text +"<b>Problema Colaborador:</b><br><b>"+sello.Colaborador+":</b> "+ sello.CodigoError+" "+ MensajeIncidencia + "<br>"
              }
            }    
            if(text !== "" || textTimbre !== ""){
              WarningAlertTimbre({
                text: textTimbre+text,
              });
              await sendEmail({
                variables:{
                 input: {
                   id:isCheck,
                   idAdmin:adminState?.id,
                   nameAdmin:adminState?.Nombre,
                   emailAdmin:adminState?.Usuario,
                   nameNomina:tab,
                   periodo:`${getDateDay(PayrollData?.init_date)}-${getDateDay(PayrollData?.end_date)} ${getDateYear(PayrollData?.end_date)}`,
                   type:"errorTimbrado"
                 }
                }
              })
            }  
          }
          setTimbrar(true)
          await sendEmail({
            variables:{
             input: {
               id:isCheck,
               idAdmin:adminState?.id,
               nameAdmin:adminState?.Nombre,
               emailAdmin:adminState?.Usuario,
               nameNomina:tab,
               periodo:`${getDateDay(PayrollData?.init_date)}-${getDateDay(PayrollData?.end_date)} ${getDateYear(PayrollData?.end_date)}`,
               type:"FinTimbrado"
             }
            }
          })
          setIsCheck([])          
        }
      }  
    }      
  }

  const timbrarNominaCancelada = async () =>{
    
    setTimbrar(false)
    let tokenTimbre = uuid();
    
    if(isCheck.length > 0){
      
      for (const payrollColaboration of isCheck){
        await updateTokenTimbreAgain({
          variables: {
            id: String(payrollColaboration),
            tokenTimbre: tokenTimbre,
            userAdd: adminState?.Usuario
          },
        })

      }


      if(tokenTimbre !== undefined){
        if( tokenTimbre !== "" ){
          const restultSELLO = await postTimbrarNomina(tokenTimbre);
          let text = ""
          let textTimbre = ""
          
          if(restultSELLO !== undefined){
  
            for (const sello of restultSELLO){
              if (sello.MensajeIncidencia === "" && String(sello.UUID) !== "" ){
                
                const restultfile = await postXMLReciboAWSDate(sello.Folio, sello.xml, sello.Colaborador, sello.Fecha);
                const pdfLink = await postPDFReciboAWS(sello.Folio, sello.Colaborador, sello.Fecha);

                await updateXML
                ({
                  variables: {
                    id:sello.Folio,
                    input: {
                      xml: restultfile,
                      LinkPDF: pdfLink
                    },
                  },
                }).then((result) => {
                  
                  postMailPayroll(sello.Folio)
                })
                textTimbre = textTimbre +"<b>Documento timbrado:</b><br><b>"+sello.Colaborador+":</b> "+ sello.UUID+"<br>"
                
              }else{

                const MensajeIncidencia = String(sello.MensajeIncidencia) === ""? "No se recibió respuesta del servicio, no se ha timbrado el documento, favor de volverlo a intentar.": sello.MensajeIncidencia
  
                text = text +"<b>Problema Colaborador:</b><br><b>"+sello.Colaborador+":</b> "+ sello.CodigoError+" "+MensajeIncidencia + "<br>"
              }
            }
    
    
            if(text !== "" || textTimbre !== ""){
              WarningAlertTimbre({
                text: textTimbre+text,
              });
            }
  
          }
          setTimbrar(true)
          setIsCheck([])
          
        }
      }

    }
      
  }

  const bottonfinish = () =>{
    
    console.log(filtrado, 'Pendientes')

    const timbrados: any = filtrado?.filter((lis:any) => lis.UUID !== null && lis.UUID !== "" && lis.Pendientes === 0)
    
    const terminado: any = filtrado?.filter((lis:any) => lis.status === 'Terminado' )

    console.log(timbrados?.length, 'Timbrados')
    console.log(terminado?.length, 'Terminados')

    return <>
    {
      (terminado?.length === 0)?
        (timbrados?.length > 0)?
        <button className={styles.pt_botonFinalizar}
        onClick={() =>
        {
            updateState(id)
        }}
        >
          <div>
            Terminar
          </div>
          <div className={styles.pc_iconoDispersar}></div>
        </button>
        :
        <button className={styles.pt_botonFinalizar}
        style={{opacity:0.5}}
        >
          <div>
            Terminar
          </div>
          <div className={styles.pc_iconoDispersar}></div>
        </button>
      :
      <button className={styles.pt_botonFinalizar}
        onClick={() =>
        {
            updateStateNomine(id)
            history.push(`/PayrollTerminar/${id}/${tab}`)
        }}
        >
          <div>
            Terminar
          </div>
          <div className={styles.pc_iconoDispersar}></div>
      </button>
    }
    </>
  }

  const exportarPDF = async (id: any) => {

    const PDF: any = await axios.get(`${server}/report/pdfPayRoll/${id}`)
    const link = document.createElement("a");
    link.href = PDF?.data?.urlPDF;
    link.setAttribute("download", "downloadtesting.PDF");
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  return (
    <>
      {!sistemaActivo ? (
        <LockedSystem/>
      ) : null}
      <Box mt={3} ml={5} className="Title">
        Nóminas
      </Box>
      <Box p={5} pb={3} pt={0}>
        <Grid container justify="flex-start">
          <CustomTabs
            //Tiene que ser 1 para que se muestre la pestaña de pre-nómina
            value={1}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <CustomTab label="Incidencias" value={0} />
            {CheckPermission("Nominas.PreNomina") && (
              <CustomTab label="Pre-Nómina" value={1} />
            )}
            {CheckPermission("Nominas.FiniquitoLiquidacion") && (
            <CustomTab label="Finiquito / Liquidación" value={2} /> )}
            {CheckPermission("Nominas.Eventuales") && (
            <CustomTab label="Eventuales" value={3} />)}
             {CheckPermission("Nominas.Historicas") && (
            <CustomTab label="Históricas" value={4} />)}
             {CheckPermission("Nominas.Calculadora") && (
            <CustomTab label="Calculadora" value={5} />)}
            {CheckPermission("Nominas.Reportes") && (
            <CustomTab label="Reportes" value={6} />)}
          </CustomTabs>
        </Grid>
      </Box>
      <div className={styles.pc_contenedorTitulo}>
        <div className={styles.pc_contenedorProceso}>
          <div>
            <span className={styles.pc_tituloProceso}>Calcular</span>
            <span className={styles.pc_tituloProceso}>Dispersar</span>
            <span className={styles.pc_tituloProceso}>Timbrar</span>
            <span className={styles.pc_tituloProceso}>Terminar</span>
          </div>
          <div className={styles.pc_contenedorIconoSeguimiento}>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pd_iconoCompleto}></div>
            </div>
            <div className={styles.pc_lineaSeguimientoCompleto}></div>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pd_iconoCompleto}></div>
            </div>
            <div className={styles.pc_lineaSeguimientoCompleto}></div>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pc_circuloBlanco}></div>    
            </div>
            <div className={styles.pc_lineaSeguimiento}></div>
            <div className={styles.pc_circuloGris}></div>
          </div>
        </div>
        <div className={styles.pc_contenedorBotones}>
          {(CheckPermission("Nominas.PreNomina.Ver.Timbrar.Timbrar") && sistemaActivo) && (
            <div>
              {
                payrollCancel?.length === 0 || payrollCancel === undefined ?
                  PayrollData?.dateFinish? null
                  :  
                    (isCheck.length > 0) && timbrar?
                      <button className={styles.pt_botonTimbrar}
                      onClick={() => timbrarNomina()}
                      >
                        <div className={styles.pd_iconoDerechaDos}></div>
                            Timbrar
                      </button>
                    :
                      <button className={styles.pt_botonTimbrarInActivo}>
                        <div className={styles.pd_iconoDerechaDos}></div>
                        Timbrar
                      </button>
                : 
                  (isCheck.length > 0) && timbrar?
                    <button className={styles.pt_botonTimbrarCancelada}
                    onClick={() => timbrarNominaCancelada()}
                    >
                      <div className={styles.pd_iconoDerechaDos}></div>
                          Timbrar de nuevo
                    </button>
                  :
                    <button className={styles.pt_botonTimbrarCanceladaInActivo}>
                      <div className={styles.pd_iconoDerechaDos}></div>
                      Timbrar de nuevo
                    </button>
              }
            </div>
          )}
          {CheckPermission("Nominas.PreNomina.Ver.Timbrar.Terminar") && (

            bottonfinish()
          )}
            
        </div>
      
      </div>


      <div className={styles.pc_contenedorPrincipal}>
      
        <div className={styles.pc_tituloContenedorPrincipal}>
          <div className={styles.pc_contenedorNombre}>
            <div>
              <span className={styles.pc_tituloPrincipal}>{tab}</span>
            </div>
            {(loading)?
              null
              :
              <div>
                <span className={styles.pc_tituloPeriodo}>Periódo: &nbsp;{getDateDay(PayrollData?.init_date)}</span>&nbsp;-&nbsp;
                <span className={styles.pc_tituloPeriodo}>{getDateDay(PayrollData?.end_date)}</span>&nbsp; 
                <span className={styles.pc_tituloPeriodo}>{getDateYear(PayrollData?.end_date)}</span>
              </div>
            }
          </div>
        </div>
        

        <div className={styles.pc_contenedorBuscadorDescargar}>
          <div className={styles.pc_contenedorBuscador}>
            <input 
              type="text" 
              placeholder="Buscar al colaborador por nombre"
              className={styles.pc_inputBuscador}
              onChange={(e) => datosFiltrados(e.target.value)}
              >
            </input>
            <div className={styles.pc_iconoBuscador}></div>
          </div>
          <button className={styles.pc_botonDescargar}  onClick={()=> handleOnExport()}>
              <div className={styles.pc_iconoDescargar}></div>
              <div>
                Descargar reporte
              </div>
          </button>
        </div>
        <div className={styles.p_contenedorTablaDispersion}>
          <table className={styles.pd_tablaPrincipal}>
            <thead className={styles.pd_contenedorTitulos}>
              <td className={`${styles.pt_columna} ${styles.pt_primerColumna}`}>
                <div id="colaboladores" className={styles.checkboxitem}>
                  <input
                    id="check"
                    type="checkbox"
                    onChange={(e) => handleSelectAll(e)}
                  ></input>
                  <label htmlFor="check"> Colaboradores </label>
                </div>
              </td>
              <td className={`${styles.pt_columna} ${styles.pt_columnaTitulos}`}>RFC</td>
              <td className={`${styles.pt_columna} ${styles.pt_columnaTitulos}`}>Percepciones</td>
              <td className={`${styles.pt_columna} ${styles.pt_columnaTitulos}`}>Deducciones</td>
              <td className={`${styles.pt_columna} ${styles.pt_columnaTitulos}`}>Total</td>
              <td className={`${styles.pt_columna} ${styles.pt_columnaTitulos}`}></td>
            </thead>
            <tbody className={styles.pc_contenedorTitulos}>
              {
                filtrado?.map((payroll:any)=>(
                  <tr>
                    <td className={`${styles.pt_columnaDatos} ${styles.pt_primerColumna}`}>
                      
                      <div id="colaboladores" className={styles.checkboxitem}>
                        <input
                          id={payroll.id}
                          key={payroll.id}
                          type="checkbox"
                          checked ={isCheck.includes(payroll.id)}
                          onChange={(e) => handleClick(e)}
                          value={payroll.id}
                          disabled={(
                                      (!payroll.UUID || payroll.UUID === "")
                                      || 
                                      ( (payroll.AcuseCancelacion && payroll.AcuseCancelacion !== "") && (payroll.UUID && payroll.UUID !== "") )
                                      ||
                                      ( 
                                        (!payroll.AcuseCancelacion || payroll.AcuseCancelacion === "") 
                                        && 
                                        (!payroll.UUID || payroll.UUID === "") 
                                        &&
                                        (payroll.PDF_back && payroll.PDF_back !== "")
                                      )
                                    )? false: true}
                        ></input>
                        <label htmlFor={payroll.id}>  {payroll.colaborator} </label>
                      
                      </div>
                      
                    </td>
                    <td className={styles.pt_columnaDatos}>{payroll.RFC}</td>
                    <td className={styles.pt_columnaDatos}>{formatter(payroll.perception)}</td>
                    <td className={styles.pt_columnaDatos}>{formatter(payroll.deduction)}</td>
                    <td className={styles.pt_columnaDatos}>{formatter(payroll.netIncomeTaxable)}</td>
                    <td className={`${styles.pt_columnaDatos}`}>
                      <div className={styles.divDoc}>
                        <div className={`${styles.divIconos}`}>
                        { (!payroll.UUID || payroll.UUID === "") 
                          || 
                          ( (payroll.AcuseCancelacion && payroll.AcuseCancelacion !== "") && (payroll.UUID && payroll.UUID !== "") )?
                          <a
                            style={{ marginRight: "5px", marginLeft: "5px" }}
                            target="_blank"
                            
                            onClick={() => {
                              exportarPDF(payroll.id);
                            }}
                            title="Vista previa"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={prefactura}
                              style={{ marginRight: "auto", marginLeft: "auto" }}
                              className="cursor"
                              alt="Vista previa"
                            />
                          </a>
                        : "" }
                        </div>
                        <div>
                          {payroll.UUID && payroll.UUID !== ""?
                            
                              <a
                                style={{ marginRight: "5px", marginLeft: "5px" }}
                                target="_blank"
                                href={payroll?.xml}
                                title="Descarga XML"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={descargaXml}
                                  style={{ marginRight: "auto", marginLeft: "auto" }}
                                  className="cursor"
                                  alt="Descarga XML"
                                />
                              </a>
                            
                          : "" }
                        </div>
                        <div>
                          {payroll.UUID && payroll.UUID !== ""?
                            <a
                              style={{ marginRight: "5px", marginLeft: "5px" }}
                              target="_blank"
                              href={payroll?.LinkPDF}
                              title="Descarga PDF"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={iconoPDF}
                                style={{ marginRight: "auto", marginLeft: "auto" }}
                                className="cursor"
                                alt="DownloadFile"
                              />
                            </a>
                            : ""
                            }
                        </div>
                        <div>
                          {payroll.PDF_back && payroll.PDF_back !== ""?
                            <a
                              style={{ marginRight: "5px", marginLeft: "5px" }}
                              target="_blank"
                              href={payroll?.PDF_back}
                              title="CFDI cuenta con timbrado previos"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={iconoPDFAnterior}
                                style={{ marginRight: "auto", marginLeft: "auto", height: "25px" }}
                                className="cursor"
                                alt="DownloadFile"
                              />
                            </a>
                            : ""
                            }
                        </div>
                      </div>
                      
                      
                      
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <div>
          <div className={styles.pd_contenedorBarraEstado}>
            <div className={styles.pd_barraEstado} >
              <div className={styles.pd_contenedorRegreso}
                onClick={() => history.push(`/payrolldispersar/${id}/${tab}`)}>
                <div className={styles.pd_botonRegreso}></div>
                <div>Regresar a "Dispersar"</div>
              </div>
              <button className={styles.pd_botonSalir}
                onClick={() => history.push(`/payroll`)}
              >
                Salir
              </button>
            </div>
          </div>
        </div>
      </div>
      <ShowTerminar type="PAYROLL" year={year}/>
    </>
  );
};

export default PayrollTimbrar;
