import { useContext, useEffect, useRef, useState } from 'react';
import { Dialog, DialogContent, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';

import styles from '../Objectives.module.css';
import ObjectivesContext, { Types } from "../../../context/ObjectiveContext/ObjectiveContext";
import AddTable from '../../../screens/Colaboradores/Colaboradores/CollaboratorTab/AddTable';
import Button from '../../Button/Button';
import { GET_OBJECTIVE_EVIDENCE,
        UPDATE_OBJECTIVE_EVIDENCE,
        DELETE_OBJECTIVE_EVIDENCE
      //  UPDATE_CAMPAIGN_OBJECTIVE 
      } from '../../../Querys/querys';
import Minus from "../../../assets/svg/minus.svg";
import { uploadEvidenceFile } from '../../../services/objectiveService';
import { ErrorAlert } from "../../../alerts/errorAlert";
import moment from 'moment';

const sanitize = require("sanitize-filename");

const AddEvidenceModal = () => {
  const { state, dispatch } = useContext(ObjectivesContext);
  const [idImg, setIdImg] = useState('')
  const [arrayEvidence, setArrayEvidence] =  useState<any[]>([])  
  const [sumPercentajeEvindence , setSumPercentajeEvindence] = useState(0)  ;
  const [editObjective] = useMutation(UPDATE_OBJECTIVE_EVIDENCE); 
  const [deleteObjective] = useMutation(DELETE_OBJECTIVE_EVIDENCE,{
    refetchQueries: [
      { query: GET_OBJECTIVE_EVIDENCE },     
    ],
  }); 

  const { data, refetch } = useQuery(GET_OBJECTIVE_EVIDENCE, {
    variables: {
      input: {
        id: parseInt(state?.selectedObjective?.id as any, 10),
      }
    },
    pollInterval: 2000,
    fetchPolicy: "no-cache",
    skip: state?.selectedObjective?.id === undefined,
  })

  
  useEffect(() => {
    if(data?.GET_OBJECTIVE_EVIDENCE?.length > 0){
      setArrayEvidence(data?.GET_OBJECTIVE_EVIDENCE)
      const last = data?.GET_OBJECTIVE_EVIDENCE.slice(-1).pop()          
      setIdImg(last.id)
      let sumPercentaje = data?.GET_OBJECTIVE_EVIDENCE.reduce((acum :any , result: any) => acum + result.result, 0); 
      setSumPercentajeEvindence(sumPercentaje)
    }else{
      setSumPercentajeEvindence(0)
    }
  }, [data?.GET_OBJECTIVE_EVIDENCE]);

  useEffect(() => {
    if (state?.selectedObjective?.id) {
      refetch({
        input: {
          id: parseInt(state?.selectedObjective?.id as any, 10),
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.selectedObjective?.id]);

  const [form, setForm] = useState<any>({
    name: "",
    nameIndicator: "",
    metricObjective: "",
    metricIndicator: "",
    weight: "",
    description: "",
    progress: 0,
  });
  const [result, setResult] = useState(0);
  const [evaluation, setEvaluation] = useState<string>("");

  useEffect(() => {
    if (state.selectedObjective) {      
      setForm({
        name: state.selectedObjective.ObjectiveName,
        nameIndicator: state.selectedObjective.nameIndicator,
        metricObjective: state.selectedObjective.metricObjective,
        metricIndicator: state.selectedObjective.metricIndicator,
        weight: state.selectedObjective.weight?.toString() + " %",
        description: state.selectedObjective.descriptionIndicator || "",
        // progress: state.selectedObjective.progress || 0,
        progress:  0,
      });
      setEvaluation(state.selectedObjective.evaluation || "");
      setResult(state.selectedObjective.result || 0);
    }
  }, [state.selectedObjective]);

  const cancelButton = () => {
    dispatch({ type: Types.CLEAR_SELECTED_OBJECTIVE });
    dispatch({ type: Types.CANCEL_ADD_EVIDENCE_MODAL });
    dispatch({ type: Types.CANCEL_EVALUATE_OBJECTIVE_MODAL });
    setForm({
      name: "",
      nameIndicator: "",
      metricObjective: "",
      metricIndicator: "",
      weight: "",
      description: "",
    });
  }

  const updateProgressForm = (e: any) => {
    let value = parseInt(e.target.value.toString().replace("$", "").replaceAll(",", ""), 10) || 
                parseInt(e.target.value.toString().replace("%", "").replaceAll(",", ""), 10)  
    if (isNaN(value) || value < 0) value = 0;    
    let objectiveString = state.selectedObjective?.metricObjective.toString().replace("$", "").replaceAll(",", "") ||
                          state.selectedObjective?.metricObjective.toString().replace("%", "").replaceAll(",", "")
    let objective=parseFloat(objectiveString??'')    
    if (value > (objective as any)) value = objective as number;
    const resultValue = value / (objective as number);
    setForm({ ...form, progress: value });
    if (resultValue > 1) {
      setResult(100);
      return;
    }
    setResult(Math.floor(resultValue * 100));
  }

  const updateProgress = () => {   

    const copyEvidence= [...arrayEvidence]
    const lastId = copyEvidence.slice(-1).pop()    
    if(sumPercentajeEvindence>= 100){
      return ErrorAlert({ text: 'la sumatoria de las evidencias es del 100%' });  
    } 

    if(lastId?.id === idImg  ){
      if(form.progress === 0 || result === 0 ){
         return ErrorAlert({ text: 'no puede guardar la evidencia sin asignarle un progreso' });  
      }
      else{
        editObjective({
          variables: {
            input: {             
              objectiveId:parseInt(idImg,10),              
              progress: parseInt(form.progress, 10),
              result: result,
            }
          }
        });
    dispatch({ type: Types.CANCEL_ADD_EVIDENCE_MODAL });
    dispatch({ type: Types.CLEAR_SELECTED_OBJECTIVE });
    setIdImg('')
    setForm({
      name: "",
      nameIndicator: "",
      metricObjective: "",
      metricIndicator: "",
      weight: "",
      description: "",
     });
     }
    }

  }

  const eliminarEvidencia = async (id:any)=>{
     await deleteObjective({
      variables: {
        input: {
          id: parseInt(id, 10)
        }
      }
    });
  }
  const inputFileRef: any = useRef(null);

  const onChangeImage = (e: any) => {    

    uploadEvidenceFile(
      e.target.files[0],
      state.selectedObjective?.id as any,
      state.selectedObjective?.idCollaborator?.toString() as any,
      sanitize(e.target.files[0].name),
    );
  }

   const uploadEvidence = () => {
    if(sumPercentajeEvindence>= 100){
      return ErrorAlert({ text: 'la sumatoria de las evidencias es del 100%' });  
    } 

    if(arrayEvidence.length>0){
    const copyEvidence= [...arrayEvidence]
    const lastEvidence = copyEvidence.slice(-1).pop()       
    if(lastEvidence?.progress === null  ){
       return ErrorAlert({ text: 'no puede guardar la evidencia sin asignarle un progreso' });        
      }
     }   
      inputFileRef.current.click();    
   }

  const sendReminder = () => {
    console.log("sendReminder");
  }

  const selectEvaluation = (e:any) => {
    setEvaluation(e.target.value);
    console.log(e.target.value)
  }

  const evaluateObjective = (e: any) => {
    dispatch({ type: Types.CLEAR_SELECTED_OBJECTIVE });
    dispatch({ type: Types.CANCEL_ADD_EVIDENCE_MODAL });
    dispatch({ type: Types.CANCEL_EVALUATE_OBJECTIVE_MODAL });
    editObjective({
      variables: {
        input: {
          id: parseInt(state?.selectedObjective?.id as any, 10),
          evaluation: evaluation,
        }
      }
    });
    setForm({
      name: "",
      nameIndicator: "",
      metricObjective: "",
      metricIndicator: "",
      weight: "",
      description: "",
    });
  }

  const getFileType = (fileName: string) => {
    if (fileName.includes('.pdf')) return '/assets/icons/icon-pdf.svg'
    if (fileName.includes('.jpg')) return '/assets/icons/icon-jpg.svg'
    if (fileName.includes('.jpeg')) return '/assets/icons/icon-jpg.svg'
    if (fileName.includes('.png')) return '/assets/icons/icon-png.svg'
    if (fileName.includes('.mp4')) return '/assets/icons/icon-mp4.svg'
    if (fileName.includes('.zip')) return '/assets/icons/icon-zip.svg'
    if (fileName.includes('.docx')) return '/assets/icons/icon-doc.svg'
    if (fileName.includes('.xlsx')) return '/assets/icons/icon-xlsx.svg'
    return '/assets/icons/icon-pdf.svg'
  }

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={state.addEvidenceModal || state.evaluateObjectiveModal}
      fullWidth={true}
    >
      <h2 id="form-dialog-title" className="text-center">
        {form.name}
      </h2>

      <DialogContent>
        <Grid direction="row" container spacing={2}>
          <Grid xs item>
            <TextField
              name="metricName"
              defaultValue={form.nameIndicator}
              label="Nombre del indicador"
              variant="outlined"
              size="small"
              fullWidth={true}
              InputProps={{
                readOnly: true,
              }}
              disabled
            />
          </Grid>
          <Grid xs item>
            <TextField
              name="metricName"
              defaultValue={form.metricIndicator}
              label="Métrica objetivo"
              variant="outlined"
              size="small"
              fullWidth={true}
              InputProps={{
                readOnly: true,
              }}
              disabled
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogContent>
        <Grid direction="row" container spacing={2}>
          <Grid xs item>
            <TextField
              name="metricName"
              defaultValue={form.metricObjective}
              label="Indicador"
              variant="outlined"
              size="small"
              fullWidth={true}
              InputProps={{
                readOnly: true,
              }}
              disabled
            />
          </Grid>
          <Grid xs item>
            <TextField
              name="metricName"
              defaultValue={form.weight}
              label="Peso"
              variant="outlined"
              size="small"
              fullWidth={true}
              InputProps={{
                readOnly: true,
              }}
              disabled
            />
          </Grid>
        </Grid>
      </DialogContent>

      {
        state.evaluateObjectiveModal && (
          <DialogContent>
            <Grid direction="row" container spacing={2}>
              <Grid xs item>
                <TextField
                  name="progress"                 
                  defaultValue={ form.progress }
                  label="Progreso"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  InputProps={{
                    readOnly: true,
                  }}
                  disabled
                />

              </Grid>
              <Grid xs item>
                <TextField
                  name="result"
                  // defaultValue={parseInt(form.progress, 10) / parseInt(form.metricObjective, 10) * 100 + " %"}
                  defaultValue={parseInt(form.progress, 10)}
                  label="Resultado"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  InputProps={{
                    readOnly: true,
                  }}
                  disabled
                />
              </Grid>
            </Grid>
          </DialogContent>
        )
      }

      <DialogContent>
       
        <Grid xs item>
          <TextField
            name="name"
            value={form.description}
            label="Descripción del objetivo"
            variant="outlined"
            size="small"
            fullWidth={true}
            InputProps={{
              readOnly: true,
            }}
            disabled
          />
        </Grid>
      </DialogContent>  
      {
        state?.campaign?.step!=='definicion'&&
          <DialogContent>
            <h4 id="form-dialog-title" className="text-center">
              Evidencias
              {data?.GET_OBJECTIVE_EVIDENCE?.length > 0  && (
                <span>{` (${data?.GET_OBJECTIVE_EVIDENCE?.length})`}</span>
              )}
            </h4>
            <table className={styles.evidenceTable}>
              <thead className={styles.evidenceTableHead}>
                <tr>
                  <th>Documento</th>
                  <th>Fecha</th>
                  <th>Archivo</th>
                  <th>Progreso</th>
                  <th>Porcentaje</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className={styles.evidenceTableBody}>
                {
                  data?.GET_OBJECTIVE_EVIDENCE?.map((evidence: any) => (
                    <tr className={styles.evidenceTr}>
                      <td className={styles.evidenceTableBodyRow}>{evidence.name}</td>
                      {/* <td className={styles.evidenceTableBodyRow}>{nameImg}</td>  */}
                      <td className={styles.evidenceTableBodyRow}>{moment(parseInt(evidence.date, 10)).format("DD/MM/YY")}</td>
                      <td className={styles.evidenceTableBodyRowIcon} onClick={() => window.open(evidence.URL, "blank")}>
                        <img src={getFileType(evidence.URL)} alt="Archivo" className={styles.evidenceTableImage} />
                      </td>
                      <td className={styles.evidenceTableBodyRow}>{evidence.progress === null? 0 : evidence.progress }</td>
                      <td className={styles.evidenceTableBodyRow}>{evidence.result   === null? 0 : evidence.result} %</td>
                      <td className={styles.evidenceTableBodyRow}>
                        <div className="" 
                            onClick={()=>{
                            eliminarEvidencia(evidence.id);                                                        
                        }} >                     
                       <img  src={Minus} alt="minus"  />
                    </div></td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </DialogContent>
      }

      {
        state.addEvidenceModal && (
          <>
            <input
              type="file"
              ref={inputFileRef}
              name="Image"
              onChange={(e) => onChangeImage(e)}
              accept=".pdf, .jpg, .jpeg, .png, .docx, .xlsx, .mp4, .zip"
            />

            <DialogContent className={styles.evidenceActions}>
              <AddTable
                func={uploadEvidence}
                text="Cargar Evidencia"
                img="upload-file-blue.svg"
              />              
              <Grid xs item>
                <TextField
                  name="metricName"
                // defaultValue={form.progress}
                  defaultValue={ state?.selectedObjective?.metricIndicator === "Pesos" ? `$ ${form.progress}` :
                                  state?.selectedObjective?.metricIndicator === "Porcentaje" ? `${form.progress} % `:form.progress }
                  label="Progreso"
                  variant="outlined"
                  size="small"
                  type='text'                                  
                  onChange={updateProgressForm}
                  onKeyPress={(event: any) => {
                    if (!/^[0-9.]+$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }} 
                 />
              </Grid>
              <span className={styles.progress}>
                {result} %
              </span>
            </DialogContent>

            <DialogContent className={styles.evidenceCancelContainer}>
              <button
                onClick={cancelButton}
                className={styles.evidenceCancelButton}
              >Cancelar</button>
              <Button text="Aceptar" func={updateProgress} />
            </DialogContent>
          </>
        )
      }

      {
        state?.campaign?.step==='revisar' && state.evaluateObjectiveModal && (
          <DialogContent style={{
            padding: '8px 30%'
          }}>
            <FormControl
              fullWidth
              size="small"
              variant="outlined"
              className="question"
              >
              <InputLabel id="demo-simple-select-label"
                className={styles.selectLabel}>Evaluar objetivo</InputLabel>
              <Select
                readOnly={state?.campaign?.status === 'finalizado' || state?.rol === 'collaborator'}
                disabled={state?.campaign?.status === 'finalizado' || state?.rol === 'collaborator'}
                className={styles.select}
                value={evaluation}
                onChange={selectEvaluation}
                labelId="demo-simple-select-label"
                id="evaluate"
                name="metricIndicator"
              >
                <MenuItem value="Cumplido">Cumplido</MenuItem>
                <MenuItem value="No cumplido">No cumplido</MenuItem>
                <MenuItem value="Requiere más información">Requiere más información</MenuItem>
                <MenuItem value="En espera">En espera</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
        )
      }    
    
      {
        state.evaluateObjectiveModal && (
          <DialogContent className={styles.evidenceCancelContainer}>
            <button
              onClick={cancelButton}
              className={styles.evidenceCancelButton}
            >
            {state?.campaign?.status === 'finalizado' ? 'Cerrar' : 'Cancelar'}</button>
            {
              state?.campaign?.step !== 'definicion'  && (
          //    state?.campaign?.step !== 'revisar' && state?.campaign?.status   !== 'finalizado' && (
                <AddTable
                  func={sendReminder}
                  text="Recordatorio"
                  img="add-icon.svg"
                />
              )
            }
            {
              state?.campaign?.step === 'revisar' &&  state?.campaign?.status   !== 'finalizado' && (
                <AddTable
                  func={evaluateObjective}
                  text="Evaluar"
                  img="add-icon.svg"
                />
              )
            }
          </DialogContent>
        )
      }

    </Dialog>
  );
};

export default AddEvidenceModal;
