import { Dispatch } from "react";
import {Types} from "./CampaignContext";

export const createCampaignsModal = (_id = "",createModalCampaigns = true, dispatch: Dispatch<any>):void =>{
    dispatch({
       type:Types.CREATE_CAMPAIGNS,
       payload:{
        _id,
        createModalCampaigns
       } 
    })
}

export const createUserKpiModal =(_id = "",createModalUserKpi = true, dispatch: Dispatch<any>):void =>{
     dispatch({
        type:Types.CREATE_USER_KPI,
        payload:{
         _id,
         createModalUserKpi
        } 
     })
 }

export const findCampaignsModal = (_id = "",findModalCampaigns = true, dispatch: Dispatch<any>):void =>{
    dispatch({
       type:Types.FIND_DATE_CAMPAIGNS,
       payload:{
        _id,
        findModalCampaigns
       } 
    })
}

export const clearCampaignsModal = ({_id="", createModalCampaigns = false,findModalCampaigns=false,createDuplicateCampaigns=false,createModalUserKpi=false}:any, dispatch: Dispatch<any>):void => {
    dispatch({
        type: Types.CLEAR_CAMPAIGNS,
        payload: {
            _id,
            createModalCampaigns,
            findModalCampaigns,
            createDuplicateCampaigns,
            createModalUserKpi
        }
    })
}
export const clearUserCampaignsModal =(_id = "",createModalUserKpi = false, dispatch: Dispatch<any>):void =>{    
dispatch({
        type: Types.CLEAR_USER_KPI_CAMPAIGNS,
        payload: {
            _id,      
            createModalUserKpi
        }
    })
}

export const createDuplicateCampaignsModal = ({_id = "",createDuplicateCampaigns = true, campaign={}}:any, dispatch: Dispatch<any>):void =>{
    console.log('Este es el segundo paso', _id,createDuplicateCampaigns)
    dispatch({
        type: Types.CREATE_DUPLICATE_CAMPAIGNS,
        payload: {
            _id,
            createDuplicateCampaigns,
            campaign
        }
    })
}



