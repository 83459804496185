import { useState, useEffect, useContext, useCallback } from "react";
import Box from '@mui/material/Box';
import styles from "../../src/components/Payroll/PaymentsStyles.module.css";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import {
  GET_LAST_BILL,
  GET_ALL_PAYMENTS,
  SEND_DISPERSION_FACTURACION_PAGOS,
  GET_COLABORADORES_PAGOS_REPORTE
} from "..//Querys/querys";
import iconoPDF from "../assets/svg/icono_descargar_pdf.svg";
import descargaXml from "../assets/svg/icono_descarga_xml.svg";
import descargaDocumento from "../assets/svg/icono_documento.svg";
import { formatter } from "../helpers/formatoMoneda";
import PayrollProcessContext from "../context/PayrollProcess/PayrollProcessContext";
import AceptarDispersarFacturacionPagos from "../components/Payroll/Modals/AceprtarDispersarFacturacionPagos";
import { openAceptarDispersarFacturacionPagos } from "../context/PayrollProcess/Actions";
import { SuccessfulAlert } from "../alerts/successAlerts";
import { ErrorAlert } from "../alerts/errorAlert";
import { Search } from "semantic-ui-react";
import { CreateExcelFacturacionPagos } from "../components/Payroll/Modals/ExcelFacturacionPagosColaboradores";


export const InitPayroll = () => {
  const {dispatch} = useContext(PayrollProcessContext)
  const [filtrado, setFiltrado] = useState([]);
  const {data: dataPayments, loading: loadingTabla, startPolling, stopPolling} = useQuery(GET_ALL_PAYMENTS);
  const [datosTablas, setDatosTablas] = useState<any>([]);
  const allPayments = dataPayments?.GET_ALL_PAYMENTS;
  const [cargandoPrincipal, setCargandoPrincipal] = useState(true)
  const [inicio, setInicio] = useState(0)
  const {loading: loadingPrincipal, data :dataPrincipal} = useQuery(GET_LAST_BILL)
  const [search, setSearch] = useState('')
  const paymentData = dataPrincipal?.GET_LAST_BILL
  const [totalPendiente, setTotalPendiente] = useState(0)
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [muestraListaPaginacion, setMuestraListaPaginacion] = useState(false)


  const [getReport, { data: reportData }] = useLazyQuery(GET_COLABORADORES_PAGOS_REPORTE);


  const [sendDispersion] = useMutation(SEND_DISPERSION_FACTURACION_PAGOS,{
    refetchQueries:[
      {query: GET_ALL_PAYMENTS}
    ]
  })

  const aceptaDispersion = async(id:any, Total:any) =>{
    if(paymentData?.saldo > Total ){
    let Array:any = []
    Array.push({ id: parseInt(id) })
    await sendDispersion({
      variables:{
        sendDispersionFacturacionPagosId: Array
      }
    })
    SuccessfulAlert({text: "Registros enviados, por favor espera la respuesta"})
    }else{
      ErrorAlert({text: "No tienes saldo suficiente para realizar el pago"})
    }
  }

  const busqueda = (e:any)  =>{
    setSearch(e.target.value)
    guardaPaginado()
  }

  const cambioTamanoPagina = (valor: number) =>{
    setMuestraListaPaginacion(!muestraListaPaginacion)
    setItemsPerPage(valor)
  }

  const guardaPaginado = useCallback(() => {
    const results = !search ? datosTablas : datosTablas?.filter(
      (dato:any) =>
        dato.Mes.toLowerCase().includes(search.toLocaleLowerCase()) ||
        dato.Total.toString().includes(search) ||
        dato.TotalCollaborators.toString().includes(search.toLocaleLowerCase())
    );
    setFiltrado(results);
  }, [datosTablas, search]);

  const getDatosTabla = useCallback(async()=>{
    setDatosTablas([...allPayments]); 
  },[allPayments])

  const handleTotalPendiente = () =>{
    const total = allPayments.reduce((sum:any, payment:any) =>{
      if(payment.statusDispersion !== 'LIQUIDADO'){
        return sum + payment.Total;
      }else{
        return sum
      }
    },0)
    setTotalPendiente(total)
  }

  const pageCount = Math.ceil(filtrado?.length / itemsPerPage);
  const itemsToShow = filtrado?.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);


  const handleDownloadFileClick = async (id:any) => {
    console.log('Este es el id', id)
    const response = await getReport({ variables: { getColaboradoresPagosReporteId: id } });
    console.log('Este es el dato', response)
    if (response.data?.GET_COLABORADORES_PAGOS_REPORTE) {
      CreateExcelFacturacionPagos(response.data.GET_COLABORADORES_PAGOS_REPORTE);
    }
  }
  
  useEffect(() =>{
    if(allPayments){
      getDatosTabla()
      guardaPaginado()
    }
  },[allPayments, startPolling, stopPolling])

  useEffect(() => {
    guardaPaginado();
  }, [search]);

  useEffect(() =>{
    if(allPayments){
      getDatosTabla();
      guardaPaginado();
      startPolling(1000);
      setFiltrado(allPayments)
      handleTotalPendiente()
    }
    return () => {
      stopPolling()
    }
  },[allPayments, startPolling, stopPolling, getDatosTabla])

  console.log('Filtrado', reportData)

  return (
    <>
      <Box mt={3} ml={5} className="Title">
        Facturación y pagos
      </Box>
      {(loadingPrincipal === true )?
      <div className={`${styles.pc_contenedorPrincipal} ${styles.tituloFacturacionPagos}`}>
          <div className={styles.contenedorLoading}>
            <div className={styles.loading}></div>
          </div>
        </div>
      :
      <div className={`${styles.pc_contenedorPrincipal} ${styles.tituloFacturacionPagos}`}>
          <>
          <div className={styles.fp_factuacionPagosConenedorTitulo}>
            <div className={styles.pc_tituloContenedorPrincipal}>
              <div className={styles.pc_contenedorNombre}>
                <div>
                  <span className={styles.pc_tituloPrincipal}>{paymentData?.Company}</span>
                </div>
              </div>
            </div>
            <div className={styles.fp_contenedorTotalColaboradores}>
              <div className={styles.textSaldo}>Acumulado Colaboradores:</div>
              <div className={`${styles.textSaldo} ${styles.fp_textTotalColaboradores}`}>{paymentData?.totalColaboradoresActivos}</div>
            </div>
          </div>
            <div className={styles.pc_contenedorBuscadorDescargar}>
              <div className={styles.pc_contenedorBuscador}>
                <input
                  value={search}
                  type="text"
                  placeholder="Buscar"
                  className={styles.pc_inputBuscador}
                  onChange={busqueda}
                ></input>
                <div className={styles.pc_iconoBuscador}></div>
              </div>
              <div className={styles.divSaldo} >
                <div className={styles.divAllSaldo}>
                  <div className={styles.textSaldo}>
                    Saldo cuenta STP
                  </div>
                  <div className={styles.saldoSTP}>
                      <span className={styles.impSaldoSTP}>{formatter(paymentData?.saldo)}</span>
                  </div>
                </div>
                <div className={styles.divAllSaldo}>
                  <div className={styles.divImpUser}>
                    <div className={styles.divTextImpAdeudo}>
                      <div className={styles.divTextAdeudo}> 
                        <span className={styles.textAdeudo}>Adeudo actual</span>
                      </div>
                      <div className={styles.divAdeudo}>
                        {(paymentData?.saldo < totalPendiente) ?
                          <span className={`${styles.impAdeudo} ${styles.fp_saldoInsuficiente}`}>{formatter(totalPendiente)}</span>
                          :
                          <span className={styles.impAdeudo}>{formatter(totalPendiente)}</span>
                        }
                      </div>
                    </div>
                    <div className={styles.divTextImpAdeudo}>
                      <div className={styles.divTextAdeudo}> 
                        <span className={styles.textAdeudo}>Precio por usuario</span>
                      </div>
                      <div className={styles.divAdeudo}> 
                        <span className={styles.impAdeudo}>{formatter(paymentData?.CostoColaborador)}</span>
                      </div>
                    </div>
                  </div>
                  
                </div>
                <div className={styles.divAllSaldo}>
                  <div className={styles.divTextDuracion}>
                    <span className={styles.textDuracion}>Duración del contrato</span>
                  </div>
                  <div className={styles.divTextDuracionFecha}>
                    <div className={styles.divTextFecha}>
                      <span className={styles.textFecha}>{paymentData?.dateStart}</span>
                    </div>
                    <div className={styles.div_Fecha}>
                      <span className={styles.textFecha}>-</span>
                    </div>
                    <div className={styles.divTextFecha}>
                      <span className={styles.textFecha}>{paymentData?.dateEnd}</span>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </>
        <div className={styles.pc_contenedorTabla}>
          <table className={styles.pc_tablaPrincipal}>
            <thead className={styles.pd_contenedorTitulos}>
              <td className={styles.pc_columnaTitulos}>Mes</td>
              <td className={styles.pc_columnaTitulos}>Total</td>
              <td className={styles.pc_columnaTitulos}>Usuarios</td>
              <td className={styles.pc_columnaTitulos}
              style={{textAlign: "center"}}
              >Desglose de usuarios</td>
              <td className={styles.pc_columnaTitulos}
              style={{textAlign: "center"}}
              >PDF</td>
              <td className={styles.pc_columnaTitulos}
              style={{textAlign: "center"}}
              >XML</td>
              <td className={styles.pc_columnaTitulos}></td>
            </thead>
            <tbody>
              {itemsToShow?.map((item: any) => (
                <tr>
                  <td className={styles.pc_columnaDatos}>
                    {item?.Mes}
                  </td>
                  <td className={styles.pc_columnaDatos}>
                    {formatter(item?.Total)}
                  </td>
                  <td className={styles.pc_columnaDatos}>
                    {item?.TotalCollaborators}
                  </td>
                  <td className={styles.pc_columnaDatos}>
                    <div className={styles.divDoc}>
                        
                        <img
                          src={descargaDocumento}
                          style={{ marginRight: "auto", marginLeft: "auto" }}
                          className="cursor"
                          alt="DownloadFile"
                          onClick={() => handleDownloadFileClick(item?.id)}
                        />
                    </div>
                      
                  </td>
                  <td className={styles.pc_columnaDatos}>
                    <div className={styles.divDoc}>
                      <a
                        style={{ marginRight: "auto", marginLeft: "auto" }}
                        target="_blank"
                        href={item?.PDF}
                        rel="noopener noreferrer"
                      >
                        <img
                          src={iconoPDF}
                          style={{ marginRight: "auto", marginLeft: "auto" }}
                          className="cursor"
                          alt="DownloadFile"
                        />
                      </a>
                    </div>
                      
                  </td>
                  <td className={styles.pc_columnaDatos}>
                  <div className={styles.divDoc}>
                    <a
                      style={{ marginRight: "auto", marginLeft: "auto" }}
                      target="_blank"
                      href={item?.XML}
                      rel="noopener noreferrer"
                    >
                      <img
                        src={descargaXml}
                        style={{ marginRight: "auto", marginLeft: "auto" }}
                        className="cursor"
                        alt="Descargar XML"
                      />
                    </a>
                  </div>
                  </td>
                  <td className={styles.pc_columnaDatos}>
                  {(item?.statusDispersion === 'ENPROCESO') ?
                    <div className={styles.fp_botonEnProceso}>
                      <span>En Proceso...</span>
                    </div>
                  :
                  (item?.statusDispersion === 'LIQUIDADO') ?
                    <div className={styles.fp_contenedorPagado}>
                      <div className={styles.iconoPagado}></div>
                      <div className={styles.textoBotonPagado}>
                        Pagado
                      </div>
                    </div>
                  :
                  (item?.statusDispersion === 'ENESPERA') ?
                    <div 
                      className={styles.fp_contenedorPagar}
                      onClick={() => aceptaDispersion(item?.id, item?.Total)}
                      >
                      <div className={styles.iconoAgegarPago}></div>
                      <span>Pagar</span>
                    </div>
                  :
                  (item?.statusDispersion === 'ERROR' || item?.statusDispersion === 'DEVUELTO') ?
                    <div className={styles.fp_contenedorReintentar}>
                      <div className={styles.fp_contenedorInternoReintentar}>
                        <div className={styles.pd_iconoError}></div>
                        <div className={styles.fp_textoError}>Error al Pagar</div>
                        <div 
                          className={styles.fp_contenedorBotonReintentar}
                          onClick={() => aceptaDispersion(item?.id, item?.Total)}
                          >
                          <div className={styles.pd_iconoIntentar}></div>
                        </div>
                      </div>
                      <div 
                        className={styles.fp_textoMensajeDispersion}
                        onClick={() => ErrorAlert({text: item?.mensajeDispersion})}
                        >{item?.mensajeDispersion}</div>
                    </div>
                  :null
                  }
                  </td>
                </tr>
              ))}
              
            </tbody>
          </table>
        </div>
        <div className={styles.contenedorPaginacion}>
          <div className={styles.columnaUnoPaginacion}>
            Pagina {currentPage + 1} de {pageCount}
          </div>
          <div
            className={`${styles.textoPaginacion} ${styles.columnaInternaPaginacion}`}

          >
            <span onClick={() => setMuestraListaPaginacion(!muestraListaPaginacion)}> Mostrar por p&aacute;gina {itemsPerPage}</span>
            <div className={styles.iconoPaginacion} onClick={() => setMuestraListaPaginacion(!muestraListaPaginacion)}></div>
            <div className={styles.contenedorListaElementos} >
              {(muestraListaPaginacion === true)?
                <ul className={styles.listaElementos}>
                  <li onClick={()=> cambioTamanoPagina(10)}>10</li>
                  <li onClick={()=> cambioTamanoPagina(20)}>20</li>
                  <li onClick={()=> cambioTamanoPagina(50)}>50</li>
                </ul>
                :null
              }
            </div>
            <div className={styles.columnaBotonesPaginacion} >
            <div
              className={styles.iconoPaginacionAnteriorActivo}
              onClick={() => setCurrentPage(prevPage => prevPage > 0 ? prevPage - 1 : prevPage)}
            ></div>
            <div className={styles.textoPaginacion}>
              {currentPage+1}/{pageCount}
            </div>
            <div
              className={styles.iconoPaginacionSiguienteActivo}
              onClick={() => setCurrentPage(prevPage => prevPage < pageCount - 1 ? prevPage + 1 : prevPage)}
            >
            </div>
          </div>
          </div>  
        </div>
      </div>
    }
      <AceptarDispersarFacturacionPagos />
    </>
  );
};

export default InitPayroll;
