import { useState, useEffect, useContext } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import moment from "moment";
import { useHistory } from 'react-router-dom';
import { Select } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { MenuCampaign } from './components/MenuCampaign';
import GreenSwitch from "../ConfigPayroll/GreenSwitch";
import { UPDATE_CAMPAIGN_ACTIVE, GET_ALL_CAMPAIGNS, UPDATE_CAMPAIGN_STEP } from "../../Querys/campaigns/querys";
import { WarningAlert } from '../../alerts/WarningAlert';
import ObjectivesContext, { Types } from '../../context/ObjectiveContext/ObjectiveContext';


import "./css/CardCampaigns.css"

interface Campaign {
  id: number
  nameCampaign: string
  startCampaign: string
  endCampaign: string
  step: string
  campaignBond: string
  descriptionBond: string
  minObjectives: number
  maxObjectives: number
  minQualification: number
  descriptionCampaign: string
  active: string
  remandingDays: number
  expectedProgress: number
  createdObjectives: number
  finishedObjectives: number
  areas: {
    id: number
    area: string
  }[]
  sedes: {
    id: number
    sede: string
  }[]
  totalCollaborators: number
  realProgress: number
}


const CampaignCard = ({ campaign }: { campaign: Campaign }) => {
  const {
    id,
    nameCampaign,
    startCampaign,
    endCampaign,
    step,
    campaignBond,
    descriptionBond,
    minObjectives,
    maxObjectives,
    minQualification,
    descriptionCampaign,
    active,
    remandingDays,
    expectedProgress,
    createdObjectives,
    finishedObjectives,
    areas,
    sedes,
    totalCollaborators,
    realProgress
  } = campaign
  const dateInitFormat = moment(startCampaign).utc().format('DD/MM/YYYY');
  const dateEndFormat = moment(endCampaign).utc().format('DD/MM/YYYY');
  const [stepValue, setStepValue] = useState("")
  const history = useHistory();
  const { dispatch } = useContext(ObjectivesContext);  

  const bono = "true";
  const [updateStatus] = useMutation(UPDATE_CAMPAIGN_ACTIVE, {
    refetchQueries: [{ query: GET_ALL_CAMPAIGNS }]
  });
  const [updateStep] = useMutation(UPDATE_CAMPAIGN_STEP, {
    refetchQueries: [{ query: GET_ALL_CAMPAIGNS }]
  });

  const updateCampaignActive = async (id: any) => {

    await updateStatus({
      variables: {
        id: id
      }
    });
  }

  useEffect(() => {
    if (stepValue === "") {
      setStepValue(step)
    }
  }, [step, stepValue]);

  const handleChange = async (id: any, e: any) => {
    setStepValue(e.target.value)
    let response = await WarningAlert({ text: "¿Deseas cambiar el status de la campaña?", showDenyButton: true, confirmButtonText: "Confirmar" });
    if (response.isConfirmed) {
      await updateStep({
        variables: {
          id: parseInt(id),
          stepValue: e.target.value,         
        }
      });
    }
    else {
      setStepValue(step)
      return
    }
  }

  const goToObjectives =async  () => {
    dispatch({
      type: Types.SET_CAMPAIGN_INFO,
      payload: campaign
    })
    dispatch({
      type: Types.SET_ROL,
      payload: 'admin'
    })
    dispatch({
      type: Types.SET_CAMPAIGN_ID,
      payload: id
    })
    history.push(`/objetivos/${id}`)
  }

  return (
    <>
      <Card className='cardCampaign'>
        <CardContent className="contentCampaign" >
          <div className='topCampaign'>
            <div>
              <div className='selectCampaign'>
                <Select
                  native
                  label={"step"}
                  name="step"
                  value={stepValue || ''}
                  onChange={(e) => { handleChange(id, e); }}
                  variant='outlined'
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    color: "#355F7A",
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    textAlign: "left",
                    backgroundColor: 'white',
                    // fontWeight: "600",
                    height: "30px",
                    width: "125px",
                  }}
                >
                  {/* <option style={{ textAlign: "center" }} value="crear">     Crear</option> */}
                  <option style={{ textAlign: "center" }} value="definicion">Definición</option>
                  <option style={{ textAlign: "center" }} value="evaluar">Evaluaci&oacute;n</option>
                  <option style={{ textAlign: "center" }} value="revisar">Revisar</option>
                </Select>
              </div>
              <div><span className='infoBono'>{campaignBond === bono ? "Con bono" : "Sin bono"}</span></div>
            </div>
            <div className='btnCampaign'>
              <div className='switchCampaign'>
                <span className='actCampaign'>{active}</span>
                <GreenSwitch
                  updatePayrollStatus={updateCampaignActive}
                  id={id}
                  status={active} 
                  />
              </div>
              <div className='menCampaign'>
              {step === "definicion" ? (
                <MenuCampaign id={id} active={active} campaign={campaign} /> 
               ):<></>
              }
              </div>
            </div>
          </div>
          <div className='infoCard'>
            <div><span className='titleCampaign'>{nameCampaign}</span></div>
            <div><span className='periodoCampaign'>{`Periodo: ${dateInitFormat} - ${dateEndFormat}`}</span></div>
          </div>
          {step === "crear" && (
            <div className='crearCard'>
                <div><span className='crearMensaje' onClick={ goToObjectives} style={{ cursor: 'pointer' }}>Comenzar a definir</span></div>
            </div>)
          }
          {step === "definicion" && (
            <div className='definirCard' onClick={goToObjectives} style={{ cursor: 'pointer' }}>
              <div className='definirContain'>
                <div><span className='obtevosTxt'>{`${createdObjectives} de ${maxObjectives * totalCollaborators}`}</span></div>
                {/* <div><span className='obtevosTxt'>{`${createdObjectives} de ${createdObjectives}`}</span></div> */}
                <div><span className='msjDefinir'>Objetivos</span> </div>
              </div>
              <div className="v-line"></div>
              <div className='definirContain'>
                <div><span className='obtevosTxt'>{`${totalCollaborators}`}</span></div>
                <div><span className='msjDefinir'>Colaboradores</span> </div>
              </div>
            </div>)
          }
          {step === "evaluar" && (
            <div className='definirCard' onClick={goToObjectives} style={{ cursor: 'pointer' }}>
              <div className='evaluarContain'>
                <div><span className='evaluarTxt'>{`${finishedObjectives} de ${createdObjectives} `}</span>  </div>
                <div className='contMsjEvaluar'><span className='msjEvaluar'>Objetivos</span> </div>
                <div className='contMsjEvaluar'><span className='msjEvaluar'>Evaluados</span> </div>
              </div>
              <div className="ev-line"></div>
              <div className='evaluarContain'>
                <div><span className='evaluarTxt'>{`${expectedProgress} %`}</span></div>
                <div><span className='msjEvaluar'>Progreso</span></div>
                <div><span className='msjEvaluar'>esperado</span> </div>
              </div>
              <div className="ev-line"></div>
              <div className='evaluarContain'>
                <div><span className='evaluarTxt'>{`${realProgress} %`}</span></div>
                <div><span className='msjEvaluar'>Progreso</span> </div>
                <div><span className='msjEvaluar'>Real</span> </div>
              </div>
            </div>)
          }
          {step === "revisar" && (
            <div className='definirCard' onClick={goToObjectives} style={{ cursor: 'pointer' }}>
              <div className='definirContain'>
                <div><span className='obtevosTxt'>{`${realProgress} %`}</span>  </div>
                <div><span className='msjDefinir'>Índice de éxito</span> </div>
              </div>
            </div>)
          }
        </CardContent>
      </Card>
    </>
  )
}

export default CampaignCard

