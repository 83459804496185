/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect } from "react";
import Box from '@mui/material/Box';
import ObjectiveRowOptions from "../Modals/ObjectiveRowOptions";
import styles from "./ObjectivesTableApprove.module.css";


const ObjectivesTableApprove = (props:any) => {
  
  const allPayrollCollaborator:any = props.rows;
  const handleClick =  (e:any) => {
    const {id, checked} = e.target
    props.setIsCheck([...props.isCheck, id])
    if(!checked){
      props.setIsCheck(props.isCheck.filter((lis:any) => lis !== id))
    }    
  }

 const handleSelectAll = (e:any) =>{
    if(!e.target.checked){
      props.setIsCheck([])
    }
    let filtradoSeleccionado = (allPayrollCollaborator.filter((lis:any)=>{
            return e.target.checked && !props.isCheck.includes(lis.id) && (!lis.UUID || lis.UUID === "") 
    }).map((lis:any) =>(lis.id)))
     props.setIsCheck(filtradoSeleccionado)
  }
  

   useEffect(() => {
   if (allPayrollCollaborator.lenght !== 0) {
     let arrayAprove: any=[]
     allPayrollCollaborator.map((lis:any)=>{
      if(lis.objetiveSteps === 'Aprobada'){
        arrayAprove.push(lis.id)    
      }
    })
    props.setIsCheck(arrayAprove)    
   } 
  }, [allPayrollCollaborator]);

  return (
    <Box display="flex" flexDirection="column" p={2}>
      <Box p={1} pb={3} display="flex" flexDirection="row">
        <Box display="flex" justifyContent="flex-start">        
        </Box>
      </Box>      
      {
       <div className={styles.pc_contenedorTabla}>
           <table className={styles.pc_tablaPrincipal}>
             <thead className={styles.pd_contenedorTitulos}> 
               <td className={styles.pc_columnaTitulos}>
                 <div id="colaboladores" className={styles.checkboxitem}>
                         <input
                           id="check"
                           type="checkbox"
                           onChange={(e) => handleSelectAll(e)}
                         ></input>
                         <label htmlFor="check"> Nombre del Objetivo </label>
                 </div>
               </td>
               <td className={styles.pc_columnaTitulos}>Indicador</td>
               <td className={styles.pc_columnaTitulos}>Tipo de Indicador</td>
               <td className={styles.pc_columnaTitulos}>Metrica Objetivo</td>
               <td className={styles.pc_columnaTitulos}>Peso</td>
               <td className={styles.pc_columnaTitulos}>Evidencias </td>
               <td className={styles.pc_columnaTitulos}>Progreso</td>
               <td className={styles.pc_columnaTitulos}>Opciones</td>               
             </thead>
             <tbody>
               {props.rows?.map((row: any, index: any) => (
                 <>
                 <tr>            
                 <td className={styles.pc_columnaDatos}>
                      <div id="objectives" className={styles.checkboxitem}>
                        <input
                          id={row.id}
                          key={row.id}
                          type="checkbox"
                          value={row.id}
                          checked ={props.isCheck.includes(row.id)}
                          onChange={(e) => handleClick(e)}                          
                        ></input>
                        <label htmlFor={row.id}>  {row.ObjectiveName} </label>
                      </div>
                  </td>
                  
                  <td className={styles.pc_columnaDatos}>{row.nameIndicator}</td>
                  <td className={styles.pc_columnaDatos}>{row.metricIndicator}</td>
                  <td className={styles.pc_columnaDatos}>{row.metricObjective}</td>
                  <td className={styles.pc_columnaDatos}>{row.weight}</td>
                  <td className={styles.pc_columnaDatos}>{row.evidenceCount}</td>
                  <td className={styles.pc_columnaDatos}>{row.progress} %</td>                
                  <td className={styles.pc_columnaDatos}><ObjectiveRowOptions {...row} /></td>                  
                 </tr>
                   
                 </>
               ))}
             </tbody>
           </table>
         </div>
          
      }
    </Box>
  );
};

export default ObjectivesTableApprove;