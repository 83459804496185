/* eslint-disable array-callback-return */
import { useContext, useState, useEffect, } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import CampaignContext from "../../../context/CampaignsContext/CampaignContext";
import { AdminContext } from "../../../context/AdminContext/AdminContext";
import { clearCampaignsModal } from "../../../context/CampaignsContext/Actions"
import { CREATE_CAMPAIGN, GET_ALL_CAMPAIGNS, GET_CAMPAIGN_BY_ID, UPDATE_CAMPAIGN } from "../../../Querys/campaigns/querys";
import {  GET_USERS_BY_CAMPUS_OR_PROJECT} from "../../../Querys/prestaciones/querys";
import { GET_ALL_PROJECT, GET_ALL_SEDE } from "../../../Querys/querys";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  Checkbox,
  ListItemText,
  MenuItem,
} from '@material-ui/core';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Form } from "semantic-ui-react";
import Add from "../../../assets/svg/addPrestacion.svg";
import { useFormik } from "formik";
import {createUserKpiModal} from "../../../context/CampaignsContext/Actions";
import AdminColaboradoresKpi from "./AdminColaboradoresKpi";
import GreenSwitchPrestacion from "../../Prestaciones/modals/GreenSwitchPrestacion"; 
import * as Yup from "yup";
import { SuccessfulAlert } from "../../../alerts/successAlerts";
import { ErrorAlert } from "../../../alerts/errorAlert";
import DateRangeField from "../../MyMood/Fields/DateRangeField";
import { addDays, subMonths, format, isEqual, parseISO } from 'date-fns';
import "../css/ModalCampaign.css"
import { v4 as uuidv4} from 'uuid'

const CreateCampaigns = () => {
  const { adminState } = useContext(AdminContext)
  const { state, dispatch } = useContext(CampaignContext);  
  const [sedes, setSedes] = useState<any>([])
  const [projects, setProjects] = useState<Array<any>>([]);
  const [selectedAllSedes, setSelectedAllSedes] = useState<String[]>([]);
  const [selectedAllProjects, setSelectedAllProjects] = useState<String[]>([]);
  const [tipodeBono ] = useState([{id:uuidv4(), nameTipoBono:'Monto Fijo', value:'Monto Fijo'},
                                              {id:uuidv4(), nameTipoBono:'Porcentaje de ingreso', value:'Porcentaje de ingreso'}]);
  const [selectedTipodeBono, setSelectedTipodeBono] = useState<String[]>([]);
  const [campaignBond, setCampaignBond] = useState(false)
  const [campaignBondDisabledInput, setCampaignBondDisabledInput] = useState(true)
  const [nameModal, setNameModal] = useState("")
  const [update, setUpdate] = useState(false)
  const [buttonDisabled,setButtonDisabled]=useState(false)
  const [stateCalendario, setStateCalendario] = useState([
    {
      startDate: subMonths(new Date(), 0),
      endDate: addDays(new Date(), 0),
      key: "selection"
    }]);
  const { data: resultSede } = useQuery(GET_ALL_SEDE);
  const allSede = resultSede?.GET_ALL_SEDE;
  const { data: resultProject } = useQuery(GET_ALL_PROJECT);
  const allProject = resultProject?.GET_ALL_PROJECT;
  const [getCampaignById] = useLazyQuery(GET_CAMPAIGN_BY_ID);
  const [campaign, setCampaign] = useState<Array<any>>([]);
  const [loadCampaign, setLoadCampaign] = useState(false);
  const [startDate, setStartDate] = useState(subMonths(new Date(), 0).toString());
  const [endDate, setEndDate] = useState(addDays(new Date(), 0).toString());
  const [errorBono, SetErrorBono] = useState(false);
  const [TypeBond, setTypeBond] = useState('')
  const [idUser, setIdUser] = useState('')
  const [getUserByCampusProject ] = useLazyQuery(GET_USERS_BY_CAMPUS_OR_PROJECT);
  const [usersByCampusProject, setUsersByCampusProject] = useState<Array<any>>([]);  
  const [colaboradoresBD,setColaboradoresBD]=useState<Array<any>>([]);
  const [selectedUser, setSelectedUser] = useState<String[]>([]);   
  const [initialValuesForm, setInitialValuesForm] = useState({
    nameCampaign: "",
    sedes: "",
    areas: "",
    minObjectives: "",
    maxObjectives: "",
    minQualification: "",
    descriptionBond: "",
    descriptionCampaign: "",
    typeBond: ""
  });
  const idInitial = 0;
  
  useEffect(() => {
    if (parseInt(state._id) === idInitial) {
      setNameModal("Crear Campaña");
      setSelectedAllSedes([]);
      setSelectedAllProjects([]);
      setCampaignBond(false);
      setInitialValuesForm({
        nameCampaign: "",
        sedes: "",
        areas: "",
        minObjectives: "",
        maxObjectives: "",
        minQualification: "",
        descriptionBond: "",
        descriptionCampaign: "",
        typeBond: ""
      });
      setStartDate(subMonths(new Date(), 0).toString());
      setEndDate(addDays(new Date(), 0).toString());
      setStateCalendario([
        {
          startDate: subMonths(new Date(), 0),
          endDate: addDays(new Date(), 0),
          key: "selection"
        }]);
      setLoadCampaign(false);
      setUpdate(false);
    }
  }, [getCampaignById, state._id, update]);

  useEffect(() => {
    if (campaignBond === false) {
      setCampaignBondDisabledInput(true)
    } else {
      setCampaignBondDisabledInput(false)
    }
  }, [campaignBond]);

  useEffect(() => {
    if (parseInt(state._id) !== idInitial && !isNaN(parseInt(state._id))) {
       const getCampaignId = async (id: any) => {       
        const idValue = (id !== '')?id :0;
        let { data: campaignInfo } = await getCampaignById({
          variables: { id: idValue },         
        })
        if (campaignInfo && campaignInfo.GET_CAMPAIGN_BY_ID) {
          setCampaign(campaignInfo.GET_CAMPAIGN_BY_ID);
        }
      }

      if (parseInt(state._id) !== idInitial && !isNaN(parseInt(state._id)) ) {
        getCampaignId(state._id);       
        setIdUser(state._id)
        setNameModal("Actualizar Campaña");
        setSelectedAllSedes([]);
        setSelectedAllProjects([]);
        setSelectedTipodeBono([]);
        setCampaignBond(false);
        setInitialValuesForm({
          nameCampaign: "",
          sedes: "",
          areas: "",
          minObjectives: "",
          maxObjectives: "",
          minQualification: "",
          descriptionBond: "",
          descriptionCampaign: "",
          typeBond: ""
        });
        setStartDate(subMonths(new Date(), 0).toString());
        setEndDate(addDays(new Date(), 0).toString());
        setStateCalendario([
          {
            startDate: subMonths(new Date(), 0),
            endDate: addDays(new Date(), 0),
            key: "selection"
          }]);
        setLoadCampaign(true);

      }
    }
  }, [getCampaignById, state._id, update]);

  useEffect(() => {
    if (campaign.length > 0 && loadCampaign) {
      const arraySede: any = [];
      campaign[0]?.sedes.map((sede: any) => {
        arraySede.push(sede.sede)
      })
      setSelectedAllSedes(arraySede)
      const arrayAreas: any = [];
      campaign[0]?.areas.map((area: any) => {
        arrayAreas.push(area.area)
      })      
      setColaboradoresBD(campaign[0]?.collaborators)  
      //console.log(colaboradoresBD.length)
      setSelectedAllProjects(arrayAreas)
      const bolCampaignBond = campaign[0]?.campaignBond === 'false' ? false : true;            
      setSelectedTipodeBono([campaign[0]?.typeBond])
      setTypeBond(campaign[0]?.typeBond)
      setCampaignBond(bolCampaignBond);
      setStateCalendario([
        {
          startDate: addDays(new Date(campaign[0]?.startCampaign), 1),
          endDate: addDays(new Date(campaign[0]?.endCampaign), 1),
          key: "selection"
        }]);

      const endDate = campaign[0]?.endCampaign.split('T')
      setStartDate(addDays(new Date(campaign[0]?.startCampaign), 1).toString())
      setEndDate(addDays(new Date(endDate[0]), 0).toString());
      setInitialValuesForm({
        nameCampaign: campaign[0]?.nameCampaign,
        sedes: arraySede,
        areas: arrayAreas,
        minObjectives: campaign[0]?.minObjectives,
        maxObjectives: campaign[0]?.maxObjectives,
        minQualification: campaign[0]?.minQualification,
        descriptionBond: campaign[0]?.descriptionBond,
        descriptionCampaign: campaign[0]?.descriptionCampaign,
        typeBond: campaign[0]?.typeBond
      });
      setUpdate(true)
    }
  }, [campaign, loadCampaign]);

  useEffect(() => { if (allProject) setProjects(allProject) }, [allProject]);

  useEffect(() => { if (allSede) setSedes(allSede) }, [allSede]);

  const onChangeLocation = (e: any) => {
    
    if (e.target.value.indexOf('Todas') > -1) {
      if (sedes.length === selectedAllSedes.length) {
        setSelectedAllSedes([]);
      } else {
        let filtered = sedes.map((item: any) => item.sedeName);
        setSelectedAllSedes(filtered);
      }
    } else {
      setSelectedAllSedes(e.target.value);
    }
  }

  const onChangeProject = (e: any) => {
    if (e.target.value.indexOf('Todas') > -1) {
      if (projects.length === selectedAllProjects.length) {
        setSelectedAllProjects([]);
      } else {
        let filtered = projects.map((item: any) => item.proyectName);
        setSelectedAllProjects(filtered);
      }
    } else {
      setSelectedAllProjects(e.target.value);
    }
  }

  const handleAdminColaborador= async ()=>{
    if(!update){ 
      setColaboradoresBD([])
    }
    if(selectedAllSedes.length === 0 || selectedAllProjects.length === 0 ){
          ErrorAlert({text:'Seleccione el Area y Sede'});
          return
        }else
        { 
         setUsersByCampusProject([])
         const proyectsName= selectedAllProjects.map((name:any)=>{
            const nameSplit=name.split('/');
            return nameSplit[0]
         })       
         let { data } = await getUserByCampusProject({
              variables: { 
                  input: {
                      sede:selectedAllSedes,
                      projects:proyectsName
                     },
                  }
              })   
            
            if(data.GET_USERS_BY_CAMPUS_OR_PROJECT.length>0){    
                setUsersByCampusProject(data.GET_USERS_BY_CAMPUS_OR_PROJECT);                            
                createUserKpiModal(state._id ,true, dispatch)                    
             } else{              
              ErrorAlert({text:'No hay colaboradores con los valores seleccionados'});
             }  
           }       
        }

  const onchageTypebond = (e: any) => {
    if(selectedTipodeBono.length > 0){
        setSelectedTipodeBono([]);       
     }else{
      setSelectedTipodeBono(e.target.value);      
      setTypeBond(e.target.value[0])
     }   
   }

  const validationSchema = () => {
    return {
      nameCampaign: Yup.string().required("Obligatorio"),
      sedes: Yup.array().required("Obligatorio").test("sedes", "Es necesario seleccionar un proyecto", function (value) {
        if ((value === undefined || value.length === 0)) {
          return false;
        } else {
          return true;
        }
      }),
      areas: Yup.array().required("Obligatorio").test("areas", "Es necesario seleccionar una sede", function (value) {
        if ((value === undefined || value.length === 0)) {
          return false;
        } else {
          return true;
        }
      }),
      minObjectives: Yup.number().required("Obligatorio")
        .positive("Tiene que ser un número positivo")
        .min(0, "Debe ser un número positivo")
        .max(99, "No puede ser mayor a 99"),
      maxObjectives: Yup.number().required("Obligatorio")
        .positive("Tiene que ser un número positivo")
        .max(99, "No puede ser mayor a 99"),
      minQualification: Yup.number().required("Obligatorio")
        .positive("Tiene que ser un número positivo")
        .max(100, "No puede ser mayor a 100"),
      // campaignBond                  
      //descriptionBond: Yup.string().required("Obligatorio")        
    }
    
  }

  const [createCampaign] = useMutation(CREATE_CAMPAIGN, {
    refetchQueries: [
      { query: GET_ALL_CAMPAIGNS }
    ],
  });

  const [updateCampaign] = useMutation(UPDATE_CAMPAIGN, {
    refetchQueries: [
      { query: GET_ALL_CAMPAIGNS }
    ],
  });


  const formik = useFormik({
    initialValues: initialValuesForm,
    enableReinitialize: true,
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (formData, { resetForm }) => {
     
      const initDate: any = format(stateCalendario[0].startDate, 'yyyy-MM-dd')
      const endDate: any = format(stateCalendario[0].endDate, 'yyyy-MM-dd')
      const dateEqual = isEqual(parseISO(initDate), parseISO(endDate))
      if (campaignBond && formData?.descriptionBond === '') {
        SetErrorBono(true)     
        return
      } else {
        SetErrorBono(false)
      }     
      if(selectedUser.length ===0 && colaboradoresBD.length ===0 ){
        ErrorAlert({text:'Es necesario agregar usuarios a la campaña'});
        return
      }   
      let arrayUserSel:any=[];
      let objUserSel:{};
      selectedUser.map((selUser:any) => {
          objUserSel ={ id:selUser.id  }
          arrayUserSel.push(objUserSel)
       })
      const minObjectives = formData?.minObjectives;
      const maxObjectives = formData?.maxObjectives;
      if (minObjectives > maxObjectives) {
        ErrorAlert({ text: 'Los objetivos mínimos deben ser menores que los máximos' });        
        return
      }
      if (dateEqual) {
        ErrorAlert({ text: 'Necesita seleccionar un rango' });     
        return
      }
      const variablesCampaign = {
        ...formData, 
        campaignBond,
        collaborators:arrayUserSel,
        sedes: selectedAllSedes,
        areas: selectedAllProjects,
        startCampaign: initDate,
        endCampaign: endDate,
        idAdmin: adminState?.id,
        typeBond:TypeBond        
      }
      // console.log(variablesCampaign)
     try {
        setButtonDisabled(true)
        if (!update) {          
           await createCampaign({
             variables: {
               input: variablesCampaign,
             },
           });
          SuccessfulAlert({ text: "Campaña creada correctamente." });
        }
        else {          
          await updateCampaign({
            variables: {
              id:parseInt(idUser),
              input: variablesCampaign
            },
          });
          SuccessfulAlert({ text: "Campaña actualizada correctamente." });
        }
        setSelectedAllSedes([]);
        setSelectedAllProjects([]);
        setSelectedTipodeBono([]);
        setColaboradoresBD([])
        setCampaignBond(false);
        setLoadCampaign(false);
        setCampaignBondDisabledInput(true)
        setUsersByCampusProject([]);
        setSelectedUser([]);
        setUpdate(false);
        resetForm()
        clearCampaignsModal(false, dispatch)
        setButtonDisabled(false)
      } catch (e) {
        ErrorAlert({ text: 'Ocurrió un error al crear u actualizar la campaña' });
        console.log("error",e)
        setButtonDisabled(false)
      }
    }
  });  

  const resetStates = () => {
    setSelectedAllSedes([]);
    setSelectedAllProjects([]);
    setSelectedTipodeBono([]);
    setUsersByCampusProject([]);
    setColaboradoresBD([])
    setSelectedUser([]);
    setTypeBond('')
    setCampaignBond(false);
    setCampaignBondDisabledInput(true)    
    SetErrorBono(false);
    setLoadCampaign(false);
    setUpdate(false);
  }

  return (
    <>
      <Dialog open={state.createModalCampaigns}
        aria-labelledby="form-dialog-title"
      >
        <IconButton aria-label="close" onClick={(() => {
          formik.resetForm();
          resetStates();
          clearCampaignsModal(false, dispatch)
        })}
          sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent  >
          <Form onSubmit={formik.handleSubmit} className="containerMdalPrestacion" >
            <DialogTitle ><span className="titlePrestacion">{nameModal}</span></DialogTitle>
            <DialogContent className="oneComponent">
              <TextField
                type="text"
                name="nameCampaign"
                label="Nombre de la campaña*"
                variant="outlined"
                size="small"
                fullWidth={true}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                inputProps={{ maxLength: 100 }}
                value={formik.values.nameCampaign}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                error={formik.touched.nameCampaign && Boolean(formik.errors.nameCampaign)}
                helperText={formik.touched.nameCampaign && formik.errors.nameCampaign}
              />
            </DialogContent>
            <DialogContent className="twoComponent" >
              <FormControl
                fullWidth
                size="small"
                variant="outlined"
                className="question"
              >
                <InputLabel id="demo-simple-select-label" style={{ backgroundColor: "#FFFFFF" }}>
                  Sede*
                </InputLabel>
                <Select
                  style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    width: "220px",
                    height: "40px"
                  }}
                  value={selectedAllSedes}
                  onChange={(e) => {
                    formik.handleChange(e);
                    onChangeLocation(e);
                  }
                  }
                  labelId="demo-simple-select-label"
                  id="sedes"
                  name="sedes"
                  multiple
                  renderValue={(selected: any) => selected.join(', ')}
                  error={formik.touched.sedes && Boolean(formik.errors.sedes)}
                >
                  <MenuItem key={0} value={'Todas'}>
                    <Checkbox checked={selectedAllSedes.length === sedes.length} style={{
                      color: "#FABB00",
                    }} />
                    <ListItemText primary={"Todas"} />
                  </MenuItem>
                  {
                    sedes.map((item: any) => (
                      <MenuItem key={item.id} value={item.sedeName}>
                        <Checkbox
                          checked={selectedAllSedes.indexOf(item.sedeName) > -1}
                          style={{
                            color: "#FABB00",
                          }}
                        />
                        <ListItemText primary={item.sedeName} />
                      </MenuItem>
                    ))
                  }
                </Select>
                <FormHelperText style={{ color: "red" }}>
                  {formik.touched.sedes && formik.errors.sedes}
                </FormHelperText>
              </FormControl>
              <FormControl
                fullWidth
                size="small"
                variant="outlined"
                className="question"
                error={formik.touched.areas && Boolean(formik.errors.areas)}
              >
                <InputLabel id="demo-simple-select-label"
                  style={{ backgroundColor: "#FFFFFF" }}
                >&Aacute;rea *</InputLabel>
                <Select
                  style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    width: "232px",
                    height: "40px"
                  }}
                  value={selectedAllProjects}
                  onChange={
                    (e) => {
                      formik.handleChange(e);
                      onChangeProject(e);
                    }
                  }
                  labelId="demo-simple-select-label"
                  id="areas"
                  name="areas"
                  multiple
                  renderValue={(selected: any) => selected.join(',')}
                  error={formik.touched.areas && Boolean(formik.errors.areas)}
                >
                  <MenuItem key={0} value={'Todas'}>
                    <Checkbox
                      checked={selectedAllProjects.length === projects.length}
                      style={{
                        color: "#FABB00",
                      }}
                    />
                    <ListItemText primary={"Todas"} />
                  </MenuItem>
                  {
                    projects.map((project: any) => (
                      <MenuItem key={project.id} value={project.proyectName}>
                        <Checkbox checked={selectedAllProjects.indexOf(project.proyectName) > -1} style={{
                          color: "#FABB00",
                        }} />
                        <ListItemText primary={project.proyectNameAndClient} />
                      </MenuItem>
                    ))
                  }
                </Select>
                <FormHelperText style={{ color: "red" }}>
                  {formik.touched.areas && formik.errors.areas}
                </FormHelperText>
              </FormControl>
            </DialogContent>
            <DialogContent className="oneComponent">
              <button className="btnAdminColaboradorKpi" type="button" onClick={() => handleAdminColaborador()} >
                  {`Administrar colaboradores (${selectedUser.length > 0 ? selectedUser.length : colaboradoresBD.length})`}
              </button> 
            </DialogContent>          
            <DialogContent className="twoComponent">
              <TextField
                type="number"
                name="minObjectives"
                label="Mínimo de objetivos *"
                variant="outlined"
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                error={formik.touched.minObjectives && Boolean(formik.errors.minObjectives)}
                helperText={formik.touched.minObjectives && formik.errors.minObjectives}
                value={formik.values.minObjectives}
                inputProps={{
                  maxLength: 100,
                  min: 0,
                  max: 99,
                  style: {
                    width: "120px",
                  },
                }}
                InputLabelProps={{ style: { fontSize: 12 } }}
              />
              <TextField
                type="number"
                name="maxObjectives"
                label="Máximo de objetivos *"
                variant="outlined"
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                error={formik.touched.maxObjectives && Boolean(formik.errors.maxObjectives)}
                helperText={formik.touched.maxObjectives && formik.errors.maxObjectives}
                value={formik.values.maxObjectives}
                inputProps={{
                  maxLength: 100,
                  min: 0,
                  max: 99,
                  style: {
                    width: "120px",
                  },
                }}
                InputLabelProps={{ style: { fontSize: 12 } }}
              />
              <TextField
                type="number"
                name="minQualification"
                label="Calificación mínima *"
                variant="outlined"
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                error={formik.touched.minQualification && Boolean(formik.errors.minQualification)}
                helperText={formik.touched.minQualification && formik.errors.minQualification}
                value={formik.values.minQualification}
                inputProps={{
                  maxLength: 100,
                  min: 0,
                  max: 100,
                  style: {
                    width: "105px",
                  },
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                InputLabelProps={{ style: { fontSize: 12 } }}
              />

            </DialogContent>
            <DialogContent className="twoComponent">
              <div className="twoComponentBono">
                <div className="bonoCampaign"><span className="spanBonoCampaign">Bono de Campaña</span></div>
                <GreenSwitchPrestacion valor={campaignBond} setValor={setCampaignBond} />
              </div>
              <div className="contTxtBono">         
                <FormControl
                fullWidth
                size="small"
                variant="outlined"
                className="question"
            //    error={formik.touched.areas && Boolean(formik.errors.areas)}
                 >
                <InputLabel id="demo-simple-select-label"
                  style={{ backgroundColor: "#FFFFFF" }}
                >Tipo de bono*</InputLabel>
                <Select
                  style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    width: "220px",
                    height: "40px"
                  }}
                 value={selectedTipodeBono}                
                  onChange={
                    (e) => {                  
                      onchageTypebond(e);
                    }
                  }
                  labelId="demo-simple-select-label"
                  id="tipoBono"
                  name="tipoBono"
                  disabled={campaignBondDisabledInput}
                  multiple
                  renderValue={(selected: any) => selected.join(',')}                
                >
                  {
                    tipodeBono.map((tipoBono: any) => (
                      <MenuItem key={tipoBono.id} value={tipoBono.value}>
                        <Checkbox checked={selectedTipodeBono.indexOf(tipoBono.nameTipoBono) > -1 } style={{
                          color: "#FABB00",
                        }} />
                        <ListItemText primary={tipoBono.nameTipoBono} />
                      </MenuItem>
                    ))
                  }
                </Select>
                {/* <FormHelperText style={{ color: "red" }}>
                  {formik.touched.areas && formik.errors.areas}
                </FormHelperText> */}
              </FormControl>
                
                <div className='textFieldBono'>
                  <TextField
                    type="text"
                    name="descriptionBond"
                    label="Escribir Bono*"
                    variant="outlined"
                    disabled={campaignBondDisabledInput}
                    size="small"
                    fullWidth={true}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    //  error={formik.touched.descriptionBond && Boolean(formik.errors.descriptionBond)}
                    value={formik.values.descriptionBond}
                    //  helperText={formik.touched.descriptionBond && formik.errors.descriptionBond} 
                    inputProps={{
                      maxLength: 120,
                      style: {
                        width: "120px",
                      },
                    }}
                  />
                </div>
                {
                  errorBono && (
                    <FormHelperText style={{ color: 'red' }}>
                      Obligatorio
                    </FormHelperText>
                  )
                }
              </div>
            </DialogContent>
            <DialogContent className="oneComponent">
              {!update ? <DateRangeField setDate={setStateCalendario}
                containerClass={"fieldsetInput"}
              ></DateRangeField>
                : <DateRangeField setDate={setStateCalendario}
                  containerClass={"fieldsetInput"}
                  daySelected1={startDate}
                  daySelected2={endDate}
                >
                </DateRangeField>}

            </DialogContent>
            <DialogContent className="oneComponent">
              <TextField
                id="outlined-multiline-static"
                label="Descripción de la campaña"
                multiline
                name="descriptionCampaign"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                fullWidth={true}
                rows={3}
                variant="outlined"
                inputProps={{ maxLength: 100 }}
                value={formik.values.descriptionCampaign}
              />
            </DialogContent>
            <DialogContent className="twoComponentBtns">
              <div className="containerButtons">
                <div className="containerCancelPreestacion">
                  <button className="btnCancelPrestacion" type="button"
                    onClick={(() => {
                      formik.resetForm();
                      resetStates();
                      clearCampaignsModal(false, dispatch);
                    }
                    )}>
                    Cancelar
                  </button>
                </div>
                <div className="containerAddPrestacion">
                  <button className="btnAddPrestacion" type="submit" disabled={ buttonDisabled}>
                    <div className="infobtnAddRestriccion">
                      <div><img src={Add} alt="add" /></div>
                      <div><span className="txtBtnAdd">{nameModal}</span> </div>
                    </div>
                  </button>
                </div>
              </div>
            </DialogContent>
          </Form>
        </DialogContent>
      </Dialog>
   
    {usersByCampusProject.length>0?   
      <AdminColaboradoresKpi 
      users={usersByCampusProject}
      userSelect={selectedUser}
      setSelectedUser={setSelectedUser}
      colaboradoresBD={colaboradoresBD}
    /> :<></>
  }
    </>
  )
}

export default CreateCampaigns

