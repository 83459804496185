import "../css/Campaigns.css"

interface props{
  filtroEstado: string,
  setFiltroEstado: any
}


const FiltroEstado = ({filtroEstado, setFiltroEstado}:props) =>{
  const cambioEstado = (estado:string) =>{
      setFiltroEstado(estado)
  }

  return(
    <>
      <div className="c_contenedorTitulosEstado">
        {/* <div className={`c_tituloProceso ${filtroEstado ==='crear'? 'c_tituloProcesoSeleccionado': ''}`} onClick={() => cambioEstado('crear')}>Crear</div> */}
        <div className={`c_tituloProceso ${filtroEstado ==='definicion'? 'c_tituloProcesoSeleccionado': ''}`} onClick={() => cambioEstado('definicion')}>Definir</div>
        <div className={`c_tituloProceso ${filtroEstado ==='evaluar'? 'c_tituloProcesoSeleccionado': ''}`} onClick={() => cambioEstado('evaluar')}>Evaluar</div>
        <div className={`c_tituloProceso ${filtroEstado ==='revisar'? 'c_tituloProcesoSeleccionado': ''}`} onClick={() => cambioEstado('revisar')}>Revisar</div>
      </div>
      <div className="c_contenedorIconoSeguimiento">
        {
        (filtroEstado ==='crear' ?
          <>
            <div className="c_circuloAmarillo" onClick={() => cambioEstado('crear')}>
              <div className="c_circuloBlanco"></div>
            </div>
            <div className="c_lineaSeguimiento" ></div>
            <div className="c_circuloGris" onClick={() => cambioEstado('definicion')}></div>
            <div className="c_lineaSeguimiento"></div>
            <div className="c_circuloGris" onClick={() => cambioEstado('evaluar')}></div>
            <div className="c_lineaSeguimiento"></div>
            <div className="c_circuloGris" onClick={() => cambioEstado('revisar')}></div>
          </>
        :
        (filtroEstado === 'definicion' ?
          <>
            {/* <div className="c_circuloGris" onClick={() => cambioEstado('crear')}></div>
            <div className="c_lineaSeguimiento"></div> */}
            <div className="c_circuloAmarillo" onClick={() => cambioEstado('definicion')}>
              <div className="c_circuloBlanco"></div>
            </div>
            <div className="c_lineaSeguimiento"></div>
            <div className="c_circuloGris" onClick={() => cambioEstado('evaluar')}></div>
            <div className="c_lineaSeguimiento"></div>
            <div className="c_circuloGris" onClick={() => cambioEstado('revisar')}></div>
        </>
        :(filtroEstado ==='evaluar'?
          <>
            {/* <div className="c_circuloGris" onClick={() => cambioEstado('crear')}></div>
            <div className="c_lineaSeguimiento"></div> */}
            <div className="c_circuloGris" onClick={() => cambioEstado('definicion')}></div>
            <div className="c_lineaSeguimiento"></div>
            <div className="c_circuloAmarillo onClick={() => cambioEstado('evaluar')}">
              <div className="c_circuloBlanco"></div>
            </div>
            <div className="c_lineaSeguimiento"></div>
            <div className="c_circuloGris" onClick={() => cambioEstado('revisar')}></div>
          </>
        :(filtroEstado ==='revisar'?
          <>
            {/* <div className="c_circuloGris" onClick={() => cambioEstado('crear')}></div>
            <div className="c_lineaSeguimiento"></div> */}
            <div className="c_circuloGris" onClick={() => cambioEstado('definicion')}></div>
            <div className="c_lineaSeguimiento"></div>
            <div className="c_circuloGris" onClick={() => cambioEstado('evaluar')}></div>
            <div className="c_lineaSeguimiento"></div>
            <div className="c_circuloAmarillo" onClick={() => cambioEstado('revisar')}>
              <div className="c_circuloBlanco"></div>
            </div>
          </>
          :null
        ))))}
      </div>
    </>
  )
}

export default FiltroEstado