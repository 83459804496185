/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import Box from '@mui/material/Box';

import SearcherTable from "../../../screens/Colaboradores/Colaboradores/CollaboratorTab/SearcherTable";
import TableBits from "../../TableBits/TableBits";
import { GET_CAMPAIGN_USERS_BY_ADMIN, GET_CAMPAIGN_USERS_BY_LEADER } from "../../../Querys/querys";
import ObjectivesContext from "../../../context/ObjectiveContext/ObjectiveContext";
import campaignUsersTableColumns from "./CampaignUsersTableColumn";
import Btnfilter from "../../Campaigns/components/Btnfilter";
import CollaboratorRowOptions from "../Modals/CollaboratorRowOptions";

import styles from "../Objectives.module.css"

const CampaignUsersTable = () => {
  const { state } = useContext(ObjectivesContext);
  const [getCampaignUsersByAdmin] = useLazyQuery(GET_CAMPAIGN_USERS_BY_ADMIN, { fetchPolicy: 'no-cache' });
  const [getCampaignUsersByLeader] = useLazyQuery(GET_CAMPAIGN_USERS_BY_LEADER, { fetchPolicy: 'no-cache' });
  const [objectivesListFilter, setObjectivesListFilter] = useState([{}]);
  const [data, setData] = useState([]);

  const getUsers = async () => {
    if (state?.rol === 'admin') {
      const { data } = await getCampaignUsersByAdmin({
        variables: {
          idCampaing: state?.campaignId
        },
        // fetchPolicy: 'no-cache'
      })
      if (data?.GET_CAMPAIGN_USERS_BY_ADMIN?.length > 0) {
        setObjectivesListFilter(data.GET_CAMPAIGN_USERS_BY_ADMIN);
        setData(data.GET_CAMPAIGN_USERS_BY_ADMIN);
      }
      return
    } else {
      const { data } = await getCampaignUsersByLeader({
        variables: {
          idCollaborator: state?.userFromLink?.id,
          idCampaing: state?.campaignId
        },
        // fetchPolicy: 'no-cache'
      })
      if (data?.GET_CAMPAIGN_USERS_BY_LEADER?.length > 0) {
        setObjectivesListFilter(data.GET_CAMPAIGN_USERS_BY_LEADER);
        setData(data.GET_CAMPAIGN_USERS_BY_LEADER);
      }
      return
    }
  }

  useEffect(() => {
    if (state?.campaignId && ((state?.rol === 'leader' && state?.userFromLink?.id) || state.rol !== 'leader')) {
      getUsers()
      const pollInterval = setInterval(getUsers, 1000)
      return () => clearInterval(pollInterval)
    }
  }, [state?.campaignId, state?.userFromLink?.id]);

  return (
    <Box display="flex" flexDirection="column" p={2}>
      <Box p={1} pb={3} display="flex" flexDirection="row">
        <Box display="flex" justifyContent="flex-start">
          <SearcherTable
            label="Buscar objetivos ..."
            initState={data || [] as any}
            setState={setObjectivesListFilter}
            fields={["name", "business", "sede", "area"]}
          />
        </Box>        
      </Box>
      {
        data.length === 0 && (
          <div className={styles.noObjectives}>
            <div className={styles.noObjectivesContainer}>
              <div className={styles.noObjectivesText}>Comienza a crear objetivos para </div>
              <div className={styles.noObjectivesText}>alcanzar todas las metas de la organización</div>
            </div>
          </div>
        )
      }
      {
        objectivesListFilter.length > 0 && (
          <TableBits
            columns={campaignUsersTableColumns}
            rows={objectivesListFilter}
            componentOptions={CollaboratorRowOptions}
            skipPermission
            skipSort
          />
        )
      }
    </Box>
  );
};

export default CampaignUsersTable;