import { useMutation, useQuery } from "@apollo/client";
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import { useHistory, useParams } from "react-router-dom";
import { useState, useEffect, useContext, useCallback } from "react";
import styles from "../../src/components/Payroll/PayrollStyles.module.css"
import { 
        GET_EVENTUALCOLLABORATORTIMBRADO,
        //UPDATE_EVENTUALCOLLABORATOR_TOKENTIMBRE,
        UPDATE_EVENTUALCOLLABORATOR_TOKENTIMBRE_DEMO,
        UPDATE_EVENTUALCOLLABORATOR_XML,
        GET_EVENTUALPAYROLL,
        GET_EVENTUALPAYROLLCOLLABORATOR,
        GET_ALL_COMPANY,
        UPDATE_EVENTUALPAYROLL_STATE,
        UPDATE_EVENTUALCOLLABORATOR_TOKENTIMBRE_TIMBRAR_AGAIN
      } from "../Querys/querys";
import CustomTabs from '../components/Collaborators/Tab/CustomTabs';
import CustomTab from '../components/Collaborators/Tab/CustomTabMain';
import {formatter} from "../helpers/formatoMoneda"
import { v4 as uuid } from "uuid";
import {
  postTimbrarNominaEventual,
} from "../services/cfdiService";
import {
  postPDFReciboEventualAWS,
  postXMLReciboAWSTypeDate,
} from "../services/candidateService";
import { WarningAlertTimbre } from "../alerts/WarningAlertTimbre";
import descargaXml from "../assets/svg/icono_descarga_xml.svg";
import CheckPermission from "../helpers/Administradores/Permissions";
import { server } from "../global/server";
import prefactura from "../assets/svg/icono_prefactura.svg";
import iconoPDF from "../assets/svg/icono_descargar_pdf.svg";
import {
  postMailEvent
} from "../services/candidateService";
import PayrollProcessContext from "../context/PayrollProcess/PayrollProcessContext";
import { openFinish } from "../context/PayrollProcess/Actions";
import ShowTerminar from "../components/Payroll/Modals/ShowTerminar/ShowTerminar"
import { AttentionAlert2 } from "../alerts/attentionAlert2";
import LockedSystem from "../alerts/LockedSystem";
import moment from "moment";
import { AdminContext } from "../context/AdminContext/AdminContext";
import { LoadingAlert } from "../alerts/loadingAlerts";
import iconoPDFAnterior from "../assets/svg/Alert_Icon.svg";
import axios from "axios";
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const EventualPayrollTimbrar = (props: TabPanelProps) => {
  const {dispatch} = useContext(PayrollProcessContext)
  const { id, tab } = useParams<any>();
  const [filtrado, setFiltrado] =  useState<any[]>([])
  const [isCheck, setIsCheck] =  useState<any[]>([])
  const [timbrar, setTimbrar] =  useState(true)
  const history = useHistory();
  const [payrollCancel, setPayrollCancel] =  useState<any[]>([])
  const {adminState} = useContext(AdminContext)

  const {loading, data:payrollDataDispersar } = useQuery(GET_EVENTUALPAYROLL, {
    variables: { getEventualPayrollId: id },
  });

  const [getPayrollDataDispersar, setGetPayrollDataDispersar] = useState<any>(payrollDataDispersar)

  const [year , setYear] = useState<any>()
  useEffect(() => {
    
    if(payrollDataDispersar){
      setYear(moment(payrollDataDispersar?.init_date).format("YYYY"))
      setGetPayrollDataDispersar(payrollDataDispersar?.GET_EVENTUALPAYROLL)
    }
  } ,[payrollDataDispersar])

  const resultPayrollTimbrado = useQuery(GET_EVENTUALCOLLABORATORTIMBRADO, {
    variables: { getSettlementPayrollcollaboratorTimbradoId: id },
  });


  const allPayrollTimbrado = resultPayrollTimbrado.data?.GET_EVENTUALCOLLABORATORTIMBRADO;

  const handleChange = () =>{
  }

  /*--------------------------sistemaActivo--------------------*/
  const { data: resultCompany, startPolling, stopPolling } = useQuery(GET_ALL_COMPANY);
  const myCompany = resultCompany?.GET_ALL_COMPANY;
  const [sistemaActivo, setSistemaActivo] = useState<any>(true);
  useEffect(()=>{
    startPolling(1000);
    return () =>{
      stopPolling()
    }
  },[startPolling,stopPolling])

  useEffect(() => {
    if(myCompany){
      setSistemaActivo(myCompany[0]?.SistemaActivo)
    }
  }, [myCompany]);
  /*--------------------------sistemaActivo--------------------*/

  const obtenerDatos = useCallback(async () => {
    await setFiltrado(allPayrollTimbrado?.filter((item:any) => item.dispersionStatus === "LIQUIDADO"))
    setPayrollCancel(
      allPayrollTimbrado?.filter((item:any) => item.AcuseCancelacion || (item.PDF_back && item.PDF_back !== ""))
    )
  }, [allPayrollTimbrado]);

  useEffect(() => {
    obtenerDatos();
  }, [allPayrollTimbrado, obtenerDatos]);

  const datosFiltrados = (e:any) =>{
    if(e !== '') {
      let expresion = new RegExp(`${e}.*`, "i")
      const nuevoFiltrado = filtrado.filter((lis:any) => expresion.test(lis.colaborator))
      setFiltrado(nuevoFiltrado)
    }else{
      setFiltrado(
        allPayrollTimbrado?.filter((item:any) => item.dispersionStatus === "LIQUIDADO")
      )
    }
  }




  const updateState = (idPayroll:any) =>{
    const payronTimbrados = filtrado?.filter((item:any) => item.UUID !== null)
    const payronNoTimbrados = filtrado?.filter((item:any) => item.UUID === null)

    if(payronNoTimbrados?.length === 0){
      openFinish({idPayroll}, dispatch)
    }
    else{

     
      AttentionAlert2({
                        text:"Se timbraron "+ payronTimbrados?.length + " nominas, faltan "+ payronNoTimbrados?.length + " nominas por timbrar"
                      });
    }

  }

//  const [updateTokenTimbre] = useMutation(UPDATE_EVENTUALCOLLABORATOR_TOKENTIMBRE);
 const [updateTokenTimbre] = useMutation(UPDATE_EVENTUALCOLLABORATOR_TOKENTIMBRE_DEMO);
  const [updateTokenTimbreAgain] = useMutation(UPDATE_EVENTUALCOLLABORATOR_TOKENTIMBRE_TIMBRAR_AGAIN);

  const [updateXML] = useMutation(UPDATE_EVENTUALCOLLABORATOR_XML,
    {
      refetchQueries: [
        {query:GET_EVENTUALCOLLABORATORTIMBRADO, variables:{getSettlementPayrollcollaboratorTimbradoId: id}},
        {query:GET_EVENTUALPAYROLLCOLLABORATOR, variables:{getEventualpayrollcollaboratorId: id}},
        ],
    });


  const handleSelectAll = (e:any) =>{

    if(!e.target.checked){
      setIsCheck([])
    }

    let filtradoSeleccionado = (allPayrollTimbrado.filter((lis:any)=>{
      return e.target.checked && !isCheck.includes(lis.id) && (
                                                                (!lis.UUID || lis.UUID === "") 
                                                                || 
                                                                ( (lis.AcuseCancelacion && lis.AcuseCancelacion !== "") && (lis.UUID && lis.UUID !== "") )
                                                                ||
                                                                ( 
                                                                  (!lis.AcuseCancelacion || lis.AcuseCancelacion === "") 
                                                                  && 
                                                                  (!lis.UUID || lis.UUID === "") 
                                                                  &&
                                                                  (lis.PDF_back && lis.PDF_back !== "")
                                                                )
                                                              )
    }).map((lis:any) =>(lis.id)))

    setIsCheck(filtradoSeleccionado)
  }

  const handleClick =  (e:any) => {
    
    const {id, checked} = e.target
    setIsCheck([...isCheck, id])
    if(!checked){
      setIsCheck(isCheck.filter((lis:any) => lis !== id))
    }

  }

  // const timbrarNomina = async () =>{
    
  //   setTimbrar(false)
  //   let tokenTimbre = uuid();

  //   if(isCheck.length > 0){

  //     for (const payrollColaboration of isCheck){
  //       await updateTokenTimbre({
  //         variables: {
  //           id: String(payrollColaboration),
  //           tokenTimbre: tokenTimbre
  //         },
  //       })

  //     }


  //     if(tokenTimbre !== undefined){
  //       if( tokenTimbre !== "" ){
  //         const restultSELLO = await postTimbrarNominaEventual(tokenTimbre);
          
  //         let text = ""
  //         let textTimbre = ""
          
  //         if(restultSELLO !== undefined){
  
  //           for (const sello of restultSELLO){
  //             if (sello.MensajeIncidencia === "" && String(sello.UUID) !== ""){

  //               const restultfile = await postXMLReciboAWSTypeDate(sello.Folio, sello.xml, sello.Colaborador, sello.Fecha, 'Eventual');
  //               const pdfLink = await postPDFReciboEventualAWS(sello.Folio, sello.Colaborador, sello.Fecha, 'Eventual');
  //               //const pdfLink = await postFilesPDFPayrollTypedate(sello.Folio, pdfB, sello.Colaborador, sello.Fecha, 'Eventual');
                
  //               await updateXML
  //               ({
  //                 variables: {
  //                   id:sello.Folio,
  //                   input: {
  //                     xml: restultfile,
  //                     LinkPDF: pdfLink
  //                   },
  //                 },
  //               }).then((res:any) => {

                  
  //                 postMailEvent(sello.Folio)
  //               })
  //               textTimbre = textTimbre +"<b>Documento timbrado:</b><br><b>"+sello.Colaborador+":</b> "+ sello.UUID+"<br>"
  //             }else{

  //               const MensajeIncidencia = sello.MensajeIncidencia === ""? "No se recibió respuesta del servicio, no se ha timbrado el documento, favor de volverlo a intentar.": sello.MensajeIncidencia
  
  //               text = text +"<b>Problema Colaborador:</b><br><b>"+sello.Colaborador+":</b> "+ sello.CodigoError+" "+MensajeIncidencia+ "<br>"
  //             }
  //           }
    
    
  //           if(text !== "" || textTimbre !== ""){
  //             WarningAlertTimbre({
  //               text: textTimbre+text,
  //             });
  //           }
  
  //         }
  //         setTimbrar(true)
  //         setIsCheck([])
          
  //       }
  //     }

  //   }
      
  // }

  const timbrarNomina = async () =>{
    let iTimbre: any = 1;
     setTimbrar(false)    
     let tokenTimbre = uuid();      
     if(isCheck.length > 0){
      const restultSELLO: any[] = [];
      LoadingAlert({ title: "Cargando..." });
      for (const payrollColaboration of isCheck){
        await updateTokenTimbre({
          variables: {
            id: String(payrollColaboration),
            tokenTimbre: tokenTimbre,
            iTimbre: iTimbre,
          },
        }).then((res:any) => {
          //console.log('res',res)          
          restultSELLO.push(res.data.UPDATE_EVENTUALCOLLABORATOR_TOKENTIMBRE_DEMO)
        })  
        iTimbre++;
      }      
      if(tokenTimbre !== undefined){
        debugger
        if( tokenTimbre != "" ){
          //const restultSELLO = await postTimbrarNomina(tokenTimbre);
          let text = ""
          let textTimbre = ""
          //console.log( restultSELLO)          
          if(restultSELLO !== undefined){
            debugger
            for (const sello of restultSELLO){
              if ((sello.MensajeIncidencia === "" || sello?.MensajeIncidencia == undefined) && sello.UUID != "" ){
                //const restultfile = await postXMLReciboAWSDate(sello.Folio, sello.xml, sello.Colaborador, sello.Fecha);
                const restultfile = sello.xml
                const pdfB = await postPDFReciboEventualAWS(sello.Folio);
                const pdfLink = await postXMLReciboAWSTypeDate(sello.Folio, pdfB, sello.Colaborador, sello.Fecha)
  
                await updateXML
                ({
                  variables: {
                    id:sello.Folio,
                    input: {
                      xml: restultfile,
                      LinkPDF: pdfB
                    },
                  },
                }).then((result) => {
                  //console.log('result',result)
                  textTimbre = textTimbre +"<b>Documento timbrado:</b><br><b>"+sello.Colaborador+":</b> "+ sello.UUID+"<br>"
                  postMailEvent(sello.Folio)
                })               
              }else{
                const MensajeIncidencia = sello.MensajeIncidencia == ""? "No se timbrar el documento": sello.MensajeIncidencia
                text = text +"<b>Problema Colaborador:</b><br><b>"+sello.Colaborador+":</b> "+ sello.CodigoError+" "+ MensajeIncidencia + "<br>"
              }
            }    
            if(text !== "" || textTimbre !== ""){
              WarningAlertTimbre({
                text: textTimbre+text,
              });
            }  
          }
          setTimbrar(true)
          setIsCheck([])          
        }
      }  
    }      
  }

  const [updatePayroll] = useMutation(UPDATE_EVENTUALPAYROLL_STATE);

  const updateStateNomine = (id: any) => {
    if(!payrollDataDispersar?.dateFinish ){
      updatePayroll({
        variables: {
          input: {
            id: id,
            statusProgress: "Terminar",
          },
        },
      });
    } 
  };


  const bottonfinish = () =>{
    
    const timbrados: any = filtrado?.filter((lis:any) => lis.UUID !== null && lis.UUID !== "")
    const terminados: any = filtrado?.filter((lis:any) => lis.status === "Terminado")

    return <>
      {
        terminados?.length === 0?
          (timbrados?.length > 0)?
            <button className={styles.pt_botonFinalizar}
            onClick={() =>
            {
              
                updateState(id)
            }}
            >
              <div>
                Terminar
              </div>
              <div className={styles.pc_iconoDispersar}></div>
            </button>
          :
            <button className={styles.pt_botonFinalizar}
            style={{opacity:0.5}}
            >
              <div>
                Terminar
              </div>
              <div className={styles.pc_iconoDispersar}></div>
            </button>
        :
          <button className={styles.pt_botonFinalizar}
          onClick={() =>
          {
            updateStateNomine(id)
              history.push(`/PayrollEventualTerminar/${id}/${tab}`)
          }}
          >
            <div>
              Terminar
            </div>
            <div className={styles.pc_iconoDispersar}></div>
          </button>
      }
    </>
  }

  const timbrarNominaCancelada = async () =>{
    setTimbrar(false)
    let tokenTimbre = uuid();
    
    if(isCheck.length > 0){
      
      for (const payrollColaboration of isCheck){
        await updateTokenTimbreAgain({
          variables: {
            id: String(payrollColaboration),
            tokenTimbre: tokenTimbre,
            userAdd: adminState?.Usuario
          },
        })

      }


      if(tokenTimbre !== undefined){
        if( tokenTimbre !== "" ){
          const restultSELLO = await postTimbrarNominaEventual(tokenTimbre);
          
          let text = ""
          let textTimbre = ""
          
          if(restultSELLO !== undefined){
  
            for (const sello of restultSELLO){
              if (sello.MensajeIncidencia === "" && String(sello.UUID) !== "" ){
  
                const restultfile = await postXMLReciboAWSTypeDate(sello.Folio, sello.xml, sello.Colaborador, sello.Fecha, 'Eventual');
                const pdfLink = await postPDFReciboEventualAWS(sello.Folio, sello.Colaborador, sello.Fecha, 'Eventual');
                //const pdfLink = await postFilesPDFPayrollTypedate(sello.Folio, pdfB, sello.Colaborador, sello.Fecha, 'Eventual');

                await updateXML
                ({
                  variables: {
                    id:sello.Folio,
                    input: {
                      xml: restultfile,
                      LinkPDF: pdfLink
                    },
                  },
                }).then((res:any) => {
                  
                
                  postMailEvent(sello.Folio)
                })
                textTimbre = textTimbre +"<b>Documento timbrado:</b><br><b>"+sello.Colaborador+":</b> "+ sello.UUID+"<br>"
              }else{

                const MensajeIncidencia = sello.MensajeIncidencia === ""? "No se recibió respuesta del servicio, no se ha timbrado el documento, favor de volverlo a intentar.": sello.MensajeIncidencia
  
                text = text +"<b>Problema Colaborador:</b><br><b>"+sello.Colaborador+":</b> "+ sello.CodigoError+" "+MensajeIncidencia+ "<br>"
              }
            }
    
    
            if(text !== "" || textTimbre !== ""){
              WarningAlertTimbre({
                text: textTimbre+text,
              });
            }
  
          }
          setTimbrar(true)
          setIsCheck([])
          
        }
      }

    }
      
  }

  const exportarPDF = async (id: any) => {
    //href={`${server}/report/pdfPayRollEventual/${payroll.id}`}
    const PDF: any = await axios.get(`${server}/report/pdfPayRollEventual/${id}`)
    const link = document.createElement("a");
    link.href = PDF?.data?.urlPDF;
    link.setAttribute("download", "downloadtesting.PDF");
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  return (
    <>
      {!sistemaActivo ? (
        <LockedSystem/>
      ) : null}
      <Box mt={3} ml={5} className="Title">
        Nóminas
      </Box>
      <Box p={5} pb={3} pt={0}>
        <Grid
          container
          justify="flex-start"
        >
          <CustomTabs
            //Tiene que ser 3 para que se muestre la pestaña de Eventuales
            value={3}
            onChange={handleChange}
            aria-label="simple tabs example"
          > 
            <CustomTab label="Incidencias" value={0} />
            {CheckPermission("Nominas.PreNomina") && (
              <CustomTab label="Pre-Nómina" value={1} />
            )}
            {CheckPermission("Nominas.FiniquitoLiquidacion") && (
            <CustomTab label="Finiquito / Liquidación" value={2} /> )}
            {CheckPermission("Nominas.Eventuales") && (
            <CustomTab label="Eventuales" value={3} />)}
             {CheckPermission("Nominas.Historicas") && (
            <CustomTab label="Históricas" value={4} />)}
             {CheckPermission("Nominas.Calculadora") && (
            <CustomTab label="Calculadora" value={5} />)}
            {CheckPermission("Nominas.Reportes") && (
            <CustomTab label="Reportes" value={6} />)}
          </CustomTabs>
        </Grid>
      </Box>
      <div className={styles.pc_contenedorTitulo}>
      {getPayrollDataDispersar?.payroll_type === "Vales de Despensa" ? (
        <div className={styles.pc_contenedorProceso}>
          <div>
            <span className={styles.pc_tituloProceso}>Calcular</span>
            <span className={styles.pc_tituloProceso}>Timbrar</span>
            <span className={styles.pc_tituloProceso}>Terminar</span>
          </div>
          <div className={styles.pc_contenedorIconoSeguimiento}>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pd_iconoCompleto}></div>
            </div>
            <div className={styles.pc_lineaSeguimientoCompleto}></div>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pc_circuloBlanco}></div>    
            </div>
            <div className={styles.pc_lineaSeguimiento}></div>
            <div className={styles.pc_circuloGris}></div>
          </div>
        </div>
         ) :
         <div className={styles.pc_contenedorProceso}>
          <div>
            <span className={styles.pc_tituloProceso}>Calcular</span>
            <span className={styles.pc_tituloProceso}>Dispersar</span>
            <span className={styles.pc_tituloProceso}>Timbrar</span>
            <span className={styles.pc_tituloProceso}>Terminar</span>
          </div>
          <div className={styles.pc_contenedorIconoSeguimiento}>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pd_iconoCompleto}></div>
            </div>
            <div className={styles.pc_lineaSeguimientoCompleto}></div>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pd_iconoCompleto}></div>
            </div>
            <div className={styles.pc_lineaSeguimientoCompleto}></div>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pc_circuloBlanco}></div>    
            </div>
            <div className={styles.pc_lineaSeguimiento}></div>
            <div className={styles.pc_circuloGris}></div>
          </div>
        </div>
        }
        <div className={styles.pc_contenedorBotones}>
          {(CheckPermission("Nominas.Eventuales.Timbrar.Timbrar") && sistemaActivo) && (
              <div>
                {
                  payrollCancel?.length === 0 || payrollCancel === undefined ?
                    getPayrollDataDispersar?.dateFinish? null
                    :
                      (isCheck.length > 0) && timbrar?
                        <button className={styles.pt_botonTimbrar}
                        onClick={() => timbrarNomina()}
                        >
                          <div className={styles.pd_iconoDerechaDos}></div>
                              Timbrar
                        </button>
                      :
                        <button className={styles.pt_botonTimbrarInActivo}>
                          <div className={styles.pd_iconoDerechaDos}></div>
                          Timbrar
                        </button>
                  :
                    (isCheck.length > 0) && timbrar?
                      <button className={styles.pt_botonTimbrarCancelada}
                      onClick={() => timbrarNominaCancelada()}
                      >
                        <div className={styles.pd_iconoDerechaDos}></div>
                            Timbrar de nuevo
                      </button>
                    :
                      <button className={styles.pt_botonTimbrarCanceladaInActivo}>
                        <div className={styles.pd_iconoDerechaDos}></div>
                        Timbrar de nuevo
                      </button>
                }
              </div>
          )}
          {CheckPermission("Nominas.Eventuales.Timbrar.Terminar") && (
            bottonfinish()
          )}
        </div>
      </div>
      <div className={styles.pc_contenedorPrincipal}>

      {loading ? null :getPayrollDataDispersar?.payroll_type === "Vales de Despensa" ? (
                <div className={styles.pc_tituloContenedorPrincipal}>
                <div className={styles.pc_contenedorNombre}>
                  <div>
                    <span className={styles.pc_tituloPrincipal}>{tab}</span>
                    &nbsp;
                  <span className={styles.pc_tituloPrincipal}>
                    {getPayrollDataDispersar?.pantryVouchersMonth}
                  </span>
                &nbsp;
                  </div>
                </div>
              </div>
            ) :  <div className={styles.pc_tituloContenedorPrincipal}>
            <div className={styles.pc_contenedorNombre}>
              <div>
                <span className={styles.pc_tituloPrincipal}>{tab}</span>
              </div>
            </div>
          </div>
        }
        <div className={styles.pc_contenedorBuscadorDescargar}>
          <div className={styles.pc_contenedorBuscador}>
            <input 
              type="text" 
              placeholder="Buscar al colaborador por nombre"
              className={styles.pc_inputBuscador}
              onChange={(e) => datosFiltrados(e.target.value)}
              >
            </input>
            <div className={styles.pc_iconoBuscador}></div>
          </div>
        </div>
        <div className={styles.p_contenedorTablaDispersion}>
          <table className={styles.pd_tablaPrincipal}>
            <thead className={styles.pd_contenedorTitulos}>
              <td className={`${styles.pt_columna} ${styles.pt_primerColumna}`}>
                <div id="colaboladores" className={styles.checkboxitem}>
                  <input
                    id="check"
                    type="checkbox"
                    onChange={(e) => handleSelectAll(e)}
                  ></input>
                  <label htmlFor="check"> Colaboradores </label>
                </div>
              </td>
              <td className={`${styles.pt_columna} ${styles.pt_columnaTitulos}`}>RFC</td>
              <td className={`${styles.pt_columna} ${styles.pt_columnaTitulos}`}>Percepciones</td>
              <td className={`${styles.pt_columna} ${styles.pt_columnaTitulos}`}>Deducciones</td>
              <td className={`${styles.pt_columna} ${styles.pt_columnaTitulos}`}>Total</td>
              <td className={`${styles.pt_columna} ${styles.pt_columnaTitulos}`}></td>
            </thead>
            <tbody className={styles.pc_contenedorTitulos}>
              {
                filtrado?.map((payroll:any)=>(
                  <tr>
                    <td className={`${styles.pt_columnaDatos} ${styles.pt_primerColumna}`}>
                      <div id="colaboladores" className={styles.checkboxitem}>
                        <input
                          id={payroll.id}
                          key={payroll.id}
                          type="checkbox"
                          checked ={isCheck.includes(payroll.id)}
                          onChange={(e) => handleClick(e)}
                          value={payroll.id}
                          disabled={(
                            (!payroll.UUID || payroll.UUID === "")
                            || 
                            ( (payroll.AcuseCancelacion && payroll.AcuseCancelacion !== "") && (payroll.UUID && payroll.UUID !== "") )
                            ||
                              ( 
                                (!payroll.AcuseCancelacion || payroll.AcuseCancelacion === "") 
                                && 
                                (!payroll.UUID || payroll.UUID === "") 
                                &&
                                (payroll.PDF_back && payroll.PDF_back !== "")
                              )
                          )? false: true}
                        ></input>
                        <label htmlFor={payroll.id}>  {payroll.colaborator} </label>
                      </div>
                    </td>
                    <td className={styles.pt_columnaDatos}>{payroll.RFC}</td>
                    <td className={styles.pt_columnaDatos}>{formatter(payroll.perception)}</td>
                    <td className={styles.pt_columnaDatos}>{formatter(payroll.deduction)}</td>
                    <td className={styles.pt_columnaDatos}>{formatter(payroll.netIncomeTaxable)}</td>
                    <td className={`${styles.pt_columnaDatos}`}>
                      <div className={styles.divDoc}>
                        <div className={`${styles.divIconos}`}>
                          {
                            (!payroll.UUID || payroll.UUID === "") 
                            || 
                            ( (payroll.AcuseCancelacion && payroll.AcuseCancelacion !== "") && (payroll.UUID && payroll.UUID !== "") )?
                              <a
                                style={{ marginRight: "5px", marginLeft: "5px" }}
                                target="_blank"
               
                                onClick={() => {
                                  exportarPDF(payroll.id);
                                }}
                                rel="noreferrer"
                              >
                                <img
                                  src={prefactura}
                                  style={{ marginRight: "auto", marginLeft: "auto" }}
                                  className="cursor"
                                  alt="Descargar"
                                />
                              </a>
                            : ""
                          }
                          
                        </div>
                        <div>
                          {payroll.UUID && payroll.UUID !== ""?
                              <a
                                style={{ marginRight: "5px", marginLeft: "5px" }}
                                target="_blank"
                                href={payroll?.xml}
                                rel="noreferrer"
                              >
                                <img
                                  src={descargaXml}
                                  style={{ marginRight: "auto", marginLeft: "auto" }}
                                  className="cursor"
                                  alt="Descargar"
                                />
                              </a>
                          : "" }
                        </div>
                        <div>
                          {payroll.UUID && payroll.UUID !== ""?
                            <a
                              style={{ marginRight: "5px", marginLeft: "5px" }}
                              target="_blank"
                              href={payroll?.LinkPDF}
                              rel="noreferrer"
                            >
                              <img
                                src={iconoPDF}
                                style={{ marginRight: "auto", marginLeft: "auto" }}
                                className="cursor"
                                alt="Descargar"
                              />
                            </a>
                            : ""
                            }
                        </div>
                        <div>
                          {payroll.PDF_back && payroll.PDF_back !== ""?
                            <a
                              style={{ marginRight: "5px", marginLeft: "5px" }}
                              target="_blank"
                              href={payroll?.PDF_back}
                              title="CFDI cuenta con timbrado previos"
                              rel="noreferrer"
                            >
                              <img
                                src={iconoPDFAnterior}
                                style={{ marginRight: "auto", marginLeft: "auto", height: "25px" }}
                                className="cursor"
                                alt="DownloadFile"
                              />
                            </a>
                            : ""
                            }
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <div>
        {getPayrollDataDispersar?.payroll_type === "Vales de Despensa" ? (
          <div className={styles.pd_contenedorBarraEstado}>
          <div className={styles.pd_barraEstado} >
            <div className={styles.pd_contenedorRegreso}
              onClick={() =>history.push(`/InitEventualPayroll/${id}/${tab}`)}
            >
            <div className={styles.pd_botonRegreso}></div>
            <div>Regresar a "Calcular"</div>
            </div>
              <button className={styles.pd_botonSalir}
                onClick={() => history.push(`/payroll`)}
              >
                Salir
              </button>
          </div>
       </div>
          ) :
            <div className={styles.pd_contenedorBarraEstado}>
            <div className={styles.pd_barraEstado} >
              <div className={styles.pd_contenedorRegreso}
                onClick={() => history.push(`/EventualPayrollDispersar/${id}/${tab}`)}>
                <div className={styles.pd_botonRegreso}></div>
                <div>Regresar a "Dispersar"</div>
              </div>
              <button className={styles.pd_botonSalir}
                onClick={() => history.push(`/payroll`)}
              >
                Salir
              </button>
            </div>
          </div>
          }
        </div>
      </div>
      <ShowTerminar type="EVENTUAL" year={year} />
    </>
  );
};

export default EventualPayrollTimbrar;
