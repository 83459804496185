/* eslint-disable array-callback-return */
import { useContext,useState,useEffect } from "react";
import CampaignContext from "../../../context/CampaignsContext/CampaignContext";
import { clearUserCampaignsModal } from "../../../context/CampaignsContext/Actions";
import {
    Dialog,     
    IconButton,
    Box,     
    TextField,
    Button
  } from '@material-ui/core';
  
  import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
  import "../css/AdminColaboradoresKpi.css"
  import { ErrorAlert } from "../../../alerts/errorAlert";
  import { v4 as uuidv4} from 'uuid'

const AdminColaboradoresKpi = ({ users,userSelect,setSelectedUser,colaboradoresBD }:any) => {
  
  const {state, dispatch} = useContext(CampaignContext);  
  const [viewtitulo, setViewTitulo]  = useState(true); 
  const [viewTxtFind, setViewTxtFind]  = useState(true); 
  const [viewtitulo2, setViewTitulo2]  = useState(true); 
  const [viewTxtFindSelec, setViewTxtFindSelec]  = useState(true);   
  const [estadoInicial, setEstadoInicial] = useState<any[]>([])
  const [seleccionadas, setSeleccionadas] = useState<any[]>([])
   
  useEffect(() => {
    if(users ){
      setEstadoInicial(users)
    }
   }, [users]);

   useEffect(() => {
    const loadUserDb = () =>{
      let arraySeleccDb:any=[]   
      if(colaboradoresBD.length > 0 && seleccionadas.length === 0){
        for (let i = 0; i < users.length; i++) {
        let igual=false;
        for (let j = 0; j < colaboradoresBD.length && !igual; j++) {
            if(parseInt(users[i]['id']) === parseInt(colaboradoresBD[j]['idUsuario'])  )
               igual=true;              
            }                 
         if(igual) arraySeleccDb.push(users[i]);
       } 
       setSeleccionadas(arraySeleccDb);       
       let arrayDb:any = [];
        for (let i = 0; i < users.length; i++) {
             let igual=false;
             for (let j = 0; j < arraySeleccDb.length && !igual; j++) {
                 if(users[i]['id'] === arraySeleccDb[j]['id'] && 
                    users[i]['bussinesName'] === arraySeleccDb[j]['bussinesName']) 
                         igual=true;
             }
            if(!igual)arrayDb.push(users[i]);
            }
        setEstadoInicial(arrayDb)    
      }  
    }    
   loadUserDb();
   }, [colaboradoresBD,seleccionadas, users]);

   useEffect(() => {
    let arraySelecc:any=[]   
    if(userSelect.length >0 ){
     for (let i = 0; i < userSelect.length; i++) {
         let igual=false;
         for (let j = 0; j < users.length && !igual; j++) {
           if(userSelect[i]['id'] === users[j]['id'] && 
              userSelect[i]['bussinesName'] === users[j]['bussinesName']) 
               igual=true;
            }          
      if(igual)arraySelecc.push(userSelect[i]);
     }      
     setSeleccionadas(arraySelecc)  
     let array:any = [];
      for (let i = 0; i < users.length; i++) {
           let igual=false;
           for (let j = 0; j < arraySelecc.length && !igual; j++) {
               if(users[i]['id'] === arraySelecc[j]['id'] && 
                  users[i]['bussinesName'] === arraySelecc[j]['bussinesName']) 
                       igual=true;
           }
          if(!igual)array.push(users[i]);
          }
      setEstadoInicial(array) 
    }
   
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [ ]); 

   const agregaTodas = () => {
  const arrayFilter=[]
  if(seleccionadas.length===0){
   setSeleccionadas(estadoInicial)
   const nuevoEstadoInicial= estadoInicial.filter((user:any) => {
    seleccionadas.filter((s:any) => {
        if (user.id === s.id ) {
          arrayFilter.push(user)
        }
    })
   })
   setEstadoInicial(nuevoEstadoInicial)
  } 
  else{
    const nuevoEstadoInicial= [...seleccionadas , ...estadoInicial]
    setSeleccionadas(nuevoEstadoInicial)
    setEstadoInicial([])
  } 
   }

   const eliminaTodas = () => {
  const arrayFilter=[]
  if(estadoInicial.length === 0){
    setEstadoInicial(seleccionadas)
   const nuevoEstadoInicial= seleccionadas.filter((user:any) => {
    estadoInicial.filter((s:any) => {
        if (user.id === s.id ) {
          arrayFilter.push(user)
        }
    })
   })
   setSeleccionadas(nuevoEstadoInicial)   
  } else{
    const nuevoEstadoInicial= [...estadoInicial , ...seleccionadas]
    setEstadoInicial(nuevoEstadoInicial)
    setSeleccionadas([])
  }
  }

const agregaSeleccionadas = (objeto: any) => {
  setEstadoInicial([...estadoInicial, { id: objeto.id, bussinesName: objeto.bussinesName,  }])
  const nuevoSeleccionadas = seleccionadas.filter((lis) => lis?.id !== objeto.id)
  setSeleccionadas(nuevoSeleccionadas)
}

const eliminaSeleccionada = (objeto: any) => {
  setSeleccionadas([...seleccionadas, { id: objeto.id, bussinesName: objeto.bussinesName,  }])
  const nuevoSeleccionadas = estadoInicial.filter((lis) => lis?.id !== objeto.id)
  setEstadoInicial(nuevoSeleccionadas)
}

const handleFind=()=>{
  if(viewTxtFind){
  setViewTitulo(false)  
  setViewTxtFind(false)
  }else{
    setViewTitulo(true)  
    setViewTxtFind(true)
  }
}

const handleChangeTxt = (e: any) => {
  if (e !=='') {
    const array=[...estadoInicial] 
    const nuevoFiltrado =array.filter((lis: any) => lis.bussinesName.toUpperCase().includes(e.toUpperCase()))
    setEstadoInicial(nuevoFiltrado)                     
   }else{
    if(seleccionadas.length >0){ 
      let array:any = [];
      for (let i = 0; i < users.length; i++) {
           let igual=false;
           for (let j = 0; j < seleccionadas.length && !igual; j++) {
               if(users[i]['id'] === seleccionadas[j]['id'] && 
                  users[i]['bussinesName'] === seleccionadas[j]['bussinesName']) 
                       igual=true;
           }
          if(!igual)array.push(users[i]);
      }
      setEstadoInicial(array)       
   }
  else{
    setEstadoInicial(users)         
  }          
 }
}    

const filtrar=()=>{
  const disponibles=[...estadoInicial]
  const nuevoFiltrado = disponibles.sort((x:any,y:any)=> x.bussinesName.localeCompare(y.bussinesName));
  setEstadoInicial(nuevoFiltrado)
}

const handleFindSel=()=>{
  if(viewTxtFindSelec){
    setViewTitulo2(false)
    setViewTxtFindSelec(false)
  }else{
    setViewTxtFindSelec(true)
    setViewTitulo2(true)
  }
}


const handleChangeTxtSel = (e: any) => {
  if(seleccionadas.length>0){
    const array=[...seleccionadas]
    if (e !== '') {
      const nuevoFiltrado =array.filter((lis: any) => lis.bussinesName.toUpperCase().includes(e.toUpperCase()))
      setSeleccionadas(nuevoFiltrado)                     
    }
    else{
     if(estadoInicial.length >0){ 
        let array:any = [];
        for (let i = 0; i < users.length; i++) {
             let igual=false;
             for (let j = 0; j < estadoInicial.length && !igual; j++) {
                 if(users[i]['id'] === estadoInicial[j]['id'] && 
                    users[i]['bussinesName'] === estadoInicial[j]['bussinesName']) 
                         igual=true;
             }
            if(!igual)array.push(users[i]);
        }
        setSeleccionadas(array)       
     }
    else{
      setSeleccionadas(users)         
    }          
  }       
 }
};    

const filtrarSelec=()=>{
  if(seleccionadas.length>0){
   const sel=[...seleccionadas]
   const nuevoFiltrado = sel.sort((x:any,y:any)=> x.bussinesName.localeCompare(y.bussinesName));
   setSeleccionadas(nuevoFiltrado)
  }
}

const cancelar = () => {
  setEstadoInicial([])
  setSeleccionadas([])
}

const handleAddUser = () => {
  if(seleccionadas.length>0){
   setSelectedUser(seleccionadas)
   setEstadoInicial([])
   setSeleccionadas([])   
   clearUserCampaignsModal(state._id,false, dispatch); 
  }
  else{
    ErrorAlert({text:'Es necesario seleccionar a un colaborador'});
  }
}  
  return (
    <Dialog open={state.createModalUserKpi} aria-labelledby="form-dialog-title"  fullWidth={true}>
      <div className="dialogUser" >
       <div >
        <div className="contenedorTitulosColumnas">
            <div className="contenedorTituloUno">
             {viewtitulo?
              <div>
              <span className="titulosColumnas">Todos los colaboradores disponibles</span>
              </div> :<></>
              } 
              <div>
                { viewTxtFind ?
                  <IconButton size="small" 
                              aria-label="search" 
                              color="inherit"
                              onClick={() => { handleFind() }}
                              >
                  <SearchIcon  color="disabled"/>
                </IconButton>
                  :  
                  <Box className='containerfind'>
                    <TextField                       
                        id="outlined-basic" 
                        label="Colaborador" 
                        size="small"
                        variant="outlined"
                        onChange={(e) => handleChangeTxt(e.target.value)}
                        />
                      <IconButton size="small" 
                              aria-label="search" 
                              color="inherit"
                              onClick={() => { handleFind() }}
                              >
                        <SearchIcon color="disabled" />
                      </IconButton>
                  </Box>
              }
          </div>
          <div  className='btnFilter'>
             <Button size="small"  onClick={() => filtrar()}  ><FilterListIcon fontSize="small" className="Filter"/> Filtrar </Button>
          </div>

            </div>
            <div className="contenedorTituloDos">
            {viewtitulo2?
              <div><span className="titulosColumnas">Colaboradores incluidos en la campaña</span></div>:
              <></>
            } 
              <div>
                { viewTxtFindSelec ?
                  <IconButton size="small" 
                              aria-label="search" 
                              color="inherit"
                              onClick={() => { handleFindSel() }}
                              >
                  <SearchIcon  color="disabled"/>
                </IconButton>
                  :  
                  <Box className='containerfind'>
                    <TextField                       
                        id="outlined-basic" 
                        label="Colaborador" 
                        size="small"
                        variant="outlined"
                        onChange={(e) => handleChangeTxtSel(e.target.value)}
                        />
                      <IconButton size="small" 
                              aria-label="search" 
                              color="inherit"
                              onClick={() => { handleFindSel() }}
                              >
                        <SearchIcon color="disabled" />
                      </IconButton>
                  </Box>
              }
          </div>
          <div className='btnFilter'>
           <Button size="small"  onClick={() => filtrarSelec()}  ><FilterListIcon fontSize="small" className="Filter"/> Filtrar </Button>
            
          </div>
            </div>
        </div>
         <div className="contenedorColumnas">
           <div   key = {uuidv4()}>
              <div className="columnaTablaIzq">
                <ul className='ulDisponibles'>
                  {
                    estadoInicial && estadoInicial.map((item: any) => {
                     return (
                      <>
                        <li className="listaGruposSeleccionadas">
                            <div
                            className="botonListaSeleccionadas"
                            onClick={() => eliminaSeleccionada({ id: item.id, bussinesName: item.bussinesName })}
                            >
                              <img className="imagenCursor" src='/assets/icons/icono-flecha-derecha.svg' alt="RightRow"/>
                            </div>
                           <div>
                           <span className="textoGrupoSeleccionadas">   {item?.bussinesName} </span>
                           </div>
                        </li>
                      </>                    
                      )
                   })
                  }
                </ul>
              </div>
            </div>
            <div id='payroll_3'>
              <div className="botonIzquierda">
                <img
                  onClick={() => eliminaTodas()}
                  className="imagenCursor"
                  src='/assets/icons/icono-flecha-izquierda.svg' 
                  alt="eliminar"
                  />
                  
              </div>
              <div className="botonDerecha">
                <img
                   onClick={() => agregaTodas()}
                  className="imagenCursor" src='/assets/icons/icono-flecha-derecha.svg'   alt="agregar"
                  />
              </div>
            </div>
            <div className="columnaTablaDer">
              <ul className='ulDisponibles'>
                 {
                  
                    seleccionadas && seleccionadas.map((item: any) => {
                      return (
                          <>
                            <li className="listaGruposDisponibles">
                              <div>
                                <span className="textoGrupoDisponibles">
                                  {item.bussinesName}
                                </span>
                              </div>
                              <div
                                className="botonListaDisponibles"
                                onClick={() => agregaSeleccionadas({ id: item.id, bussinesName: item.bussinesName })}
                                >
                                <img className="imagenCursor"  src='/assets/icons/icono-flecha-izquierda.svg' alt="leftRow" />
                              </div>
                            </li>
                          </>                       
                      )
                    })
                  }                 
              </ul>
            </div>
          </div>
        </div>
         <div className="containerButtons">
              <div className="containerCancelPreestacion">
                  <button className="btnCancelPrestacion" type="button" 
                  onClick={(() =>{
                       cancelar();                       
                       clearUserCampaignsModal (state._id,false, dispatch); 
                          }
                          )}>
                      Cancelar
                  </button>             
              </div>    
            <div className="containerAddPrestacion">
            <button className="btnAddPrestacionColaborador" type="button" 
            onClick={(() =>{handleAddUser()})}>Aplicar</button>             
        </div>    
        </div> 
      </div>    
  </Dialog>   
  )
  
}

export default AdminColaboradoresKpi