/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { useQuery } from "@apollo/client";
import BtnCreate from "./components/BtnCreate";
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import SearcherCard from "./components/SearcherCard";
import {
  GET_ALL_CAMPAIGNS
} from "../../Querys/campaigns/querys";
import CampaignCard from "./CampaignCard";
import Btnfilter from "./components/Btnfilter";
import FechaRango from "./modals/FechaRango"
import "./css/Campaigns.css"
import FiltroEstado from "./components/FiltroEstado";
import CreateDuplicateCampaigns from "./modals/CreateDuplicateCampaigns";
import ObjectivesContext, { Types } from "../../context/ObjectiveContext/ObjectiveContext";

const Campaigns = () => {
  const [campaignFilter, setcampaignFilter] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [campaign, setCampaign] = useState([]);
  const { data: campaignsInfo, loading,startPolling, stopPolling } = useQuery(GET_ALL_CAMPAIGNS);
  const [filtroEstado, setFiltroEstado] = useState('definicion')
  const [stepFilter, setStepFilter] = useState([])
  const [stepCampaignFilter, setCampaignStepFilter] = useState([])
  const [counter, setCounter] = useState(0)
  const { dispatch } = useContext(ObjectivesContext);

  const cambioStep = () => {
    let registros = (campaignsInfo?.GET_ALL_CAMPAIGNS?.filter((e: any) => e.step === filtroEstado))
    setStepFilter(registros)
    setCampaignStepFilter(registros)
  }

  useEffect(()=>{
    startPolling(2000);
    return () =>{
        stopPolling()
    }
},[startPolling,stopPolling])

  useEffect(() => {
    if (campaignsInfo && campaignsInfo.GET_ALL_CAMPAIGNS) {
      setCampaigns(campaignsInfo.GET_ALL_CAMPAIGNS);
      setcampaignFilter(campaignsInfo.GET_ALL_CAMPAIGNS);
      setCampaign(campaignsInfo.GET_ALL_CAMPAIGNS);      
    }
  }, [campaignsInfo]);

  useEffect(() => {
    if (campaignFilter.length > 0) {
      const orderFilter = [...campaignFilter]
      orderFilter.sort((a: any, b: any) => { return (a.id - b.id) })
      setCampaigns(orderFilter);
    }
    cambioStep()
  }, [campaignFilter]);

  useEffect(() => {
    cambioStep()
    setCounter(stepFilter.length)
  }, [filtroEstado])

  useEffect(() => {
    dispatch({ type: Types.CLEAR_OBJECTIVE_STATE });
    dispatch({ type: Types.SET_ROL, payload: 'admin' });
  }, []);

  return (
    <>
      <div className="c_contenedorTitulo">
        <div className="c_contenedorProceso">
          <FiltroEstado filtroEstado={filtroEstado} setFiltroEstado={setFiltroEstado} />
        </div>
        <Box>
          <BtnCreate />
        </Box>
      </div>

      {(loading === true) ?
        <div className="c_contenedorPrincipal">
          <div className="contenedorLoadingPrincipal">
            <div className="loadingPrincipal"></div>
          </div>
        </div>
        :
        <div className="c_contenedorPrincipal">
          <Grid container className="screenBtns">
            <Grid className="findCampaign">
              <SearcherCard
                initState={campaigns}
                setState={setcampaignFilter}
                stateInitial={campaign}
                setStepFilter={setStepFilter}
                stepCampaignFilter={stepCampaignFilter}
                label={"Buscar"}
                fields={['titulo']}
                width={400}
              />
            </Grid>
     
          </Grid>
          <Grid container className="cardsCampaign" >
            <Grid container spacing={2}>
              {
                stepFilter?.length > 0 ?
                  stepFilter?.map((campaign: any) => {
                    return (
                      <Grid item key={campaign.id}>
                        <CampaignCard
                          campaign={campaign}
                        />
                      </Grid>
                    )
                  }) :
                  campaigns?.length > 0 ?
                    // campaigns?.map((campaign: any) => {
                    //   return (
                    //     <Grid item key={campaign.id}>
                    //       <CampaignCard
                    //         campaign={campaign}
                    //       />
                    //     </Grid>
                    //   )
                    // })
                    <div className="contenedorMensajeCarga">
                    <div className="mensajeCarga"> Por el momento no existen campañas con este estatus</div>
                    </div>            
                    :
                    <div className="contenedorMensajeCarga">
                      <div className="mensajeCarga"> Comienza a crear campañas para alcanzar todas las metas de la organización</div>
                    </div>
              }
            </Grid>
          </Grid>
        </div>
      }
      <FechaRango setState={setcampaignFilter} setStepFilter={setStepFilter} />
      <CreateDuplicateCampaigns />
    </>

  )
}

export default Campaigns
