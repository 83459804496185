import {useState, useEffect, useContext, useCallback} from "react";
import { Grid } from "@material-ui/core";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useHistory } from "react-router-dom";
import '../ConfigPayroll/tableISR.css'
import '../ConfigPayroll/PayrollGroup.css'
import { useLazyQuery, useQuery } from "@apollo/client";
import {  
        GET_REPORTEPOLIZA_CONTABLE,
        GET_DISPERSIONACEPTADA_ZIP,
        GET_ALL_PAYROLLCOLLABORATORSETTLEMENTEVENT_EXCELL
      } from "../../Querys/querys";
import {formatter} from "../../helpers/formatoMoneda"
import { getDateMonthYear, getDateMonth } from "../../helpers/Payroll/Payroll";
import styles from "../../../src/components/Payroll/PayrollStyles.module.css";
import moment from "moment";
import openBlok from "../../assets/svg/openBlok.svg";
import Cancel from "../../assets/svg/Cancel.svg";
import PayrollProcessContext from "../../context/PayrollProcess/PayrollProcessContext";
import {openCancelTimbre} from "../../context/PayrollProcess/Actions";
import {DataCancelTimbre} from "../Payroll/Modals/CancelTimbre/DataCancelTimbre"
import { CreateExcelReporteNomina } from "./Modals/ExcelPayrollHReporteN";
import { CreateExcelReportePolizaA } from "./Modals/ExcelPayrollHReportePP";
import { server } from "../../global/server";
import {CreateExcelFileDispersionAceptada} from "../../components/Payroll/Modals/ExcelDispersionAceptada";
import axios from "axios";
import CheckPermission from "../../helpers/Administradores/Permissions";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    year: any;
    filtro: any;
    allHistoric: any;
};

const HistoricPayrollTable = (props: TabPanelProps) =>{
  const {year, allHistoric} = props
  const [iconoSemanal, setIconoSemanal] = useState(false)
  const {state,dispatch} = useContext(PayrollProcessContext)

  const [idPayrolDispersar, setIdPayrolDispersar] = useState('0')
  const [typePayrolDispersar, setTypePayrolDispersar] = useState('')
  const [idReportPayroll, setIdReportPayroll] = useState('0')
  const [typeReportPayroll, setTypeReportPayroll] = useState('')
  const [showZipSTP, setShowZipSTP] = useState('0')
  const [showZipNominas, setShowZipNominas] = useState('0')

  const [getAllPayrollCollaboratorExcell, { data: resultPayrollCollaboratorExcell }] = useLazyQuery(
    GET_ALL_PAYROLLCOLLABORATORSETTLEMENTEVENT_EXCELL,
    {
      variables:{
        getAllPayrollcollaboratorExcellId: idReportPayroll, type: typeReportPayroll
      },
    }
  );
  
  const allPayrollCollaboratorExcell = resultPayrollCollaboratorExcell?.GET_ALL_PAYROLLCOLLABORATORSETTLEMENTEVENT_EXCELL;

  const handleOnExport = useCallback((id: any) => {
    if(resultPayrollCollaboratorExcell?.GET_ALL_PAYROLLCOLLABORATORSETTLEMENTEVENT_EXCELL !== undefined){
      CreateExcelReporteNomina(resultPayrollCollaboratorExcell?.GET_ALL_PAYROLLCOLLABORATORSETTLEMENTEVENT_EXCELL)
      setIdReportPayroll('0')
      setTypeReportPayroll('')
    }
  },[resultPayrollCollaboratorExcell])

  useEffect(()=>{
    if(idReportPayroll !== '0' && typeReportPayroll !== ''){
      getAllPayrollCollaboratorExcell()
    }
    
  },[idReportPayroll, typeReportPayroll, getAllPayrollCollaboratorExcell])

  useEffect(()=>{
    if(allPayrollCollaboratorExcell && allPayrollCollaboratorExcell !== undefined && idReportPayroll !== '0'){
      handleOnExport(idReportPayroll)
    }
  },[allPayrollCollaboratorExcell,idReportPayroll, handleOnExport])

  const resultListaRaya = useQuery(GET_REPORTEPOLIZA_CONTABLE,{
    variables: { getReportepolizaContableId: 11},

  });

  const allListaRayaExcell = resultListaRaya.data?.GET_REPORTEPOLIZA_CONTABLE;

  const [getDispersionZip, { data: dataPayrolDispersar }] = useLazyQuery(
    GET_DISPERSIONACEPTADA_ZIP,
    {
      variables:{
        id_payroll: parseInt(idPayrolDispersar),
        type: typePayrolDispersar
      },
    }
  );
  const allDisoersiones = dataPayrolDispersar?.GET_DISPERSIONACEPTADA_ZIP

  const handleOnExportCEP = useCallback((id: any) => {
    if(dataPayrolDispersar?.GET_DISPERSIONACEPTADA_ZIP !== undefined){
      CreateExcelFileDispersionAceptada(dataPayrolDispersar?.GET_DISPERSIONACEPTADA_ZIP)
      setIdPayrolDispersar('0')
      setTypePayrolDispersar('')
    }
  },[dataPayrolDispersar])

  useEffect(()=>{
    if(idPayrolDispersar !== '0'){
      getDispersionZip()
    }
    
  },[idPayrolDispersar,getDispersionZip])

  useEffect(()=>{
    if(allDisoersiones && allDisoersiones !== undefined && idPayrolDispersar !== '0'){
      handleOnExportCEP(idPayrolDispersar)
    }
  },[allDisoersiones,idPayrolDispersar, handleOnExportCEP])

  let filterExcelCollaborator: any[]=[]

  let titlePlayRoll: any[]=[]
  let titlePlayRollNotRepeated: any[]=[]
  let found: any ={}

  allHistoric?.forEach((playRoll: any) =>{
    titlePlayRoll.push(moment(playRoll.init_date).format('MMMM'))
  })

  titlePlayRollNotRepeated = titlePlayRoll.filter( (element: any) =>{
    return found.hasOwnProperty(element)? false : (found[element]=true);
  })

  const history = useHistory();

  const handleOnExportListaRaya = (id: any) => {
    CreateExcelReportePolizaA(allListaRayaExcell);
  }

  const openDataCancel = (_id: any, typeCancelTimbre: any) =>{
    
    openCancelTimbre({_id, typeCancelTimbre},dispatch)
  }
  
  const openPayroll = (lis: any) =>{
    let type: any =''
    let rute: any = ''

    if(String(lis?.payroll_type) === 'Eventual'){
      type = 'InitEventualPayroll'
    }else{
      if(String(lis?.payroll_type) === 'Finiquito'){
        type = 'InitSettlement'
        rute = 'Finiquito'
        
      }else{
        type = 'InitPayroll'
      }

    }
      
    return <img className={styles.pc_iconoFlechaAmarilla} src='/assets/svg/icono-FlechaAmarilla.png' onClick={() => history.push(`/${type}/${lis?.id}/${lis?.group_name}/${rute}`)} 
    alt="Nomina"
    ></img>
    
  }
  const esExtraordinaria = (namePayroll: any) =>{
 
    if(namePayroll?.includes('Extraordinaria')){
      return true
    }else{
     return false
    }
  }

  const showZIP = (url: any) =>{
    const link = document.createElement("a");
    link.href = url?.data?.urlPDF;
    link.setAttribute("download", "downloadtesting.PDF");
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  const exportarPDF = async (id: any, payroll_type: any) => {
    
    setShowZipSTP(id)
    
    const doc: any = await axios.get(`${server}/report/zipAcceptedDispersion/${id}/${payroll_type}`)
    const url: any =await axios.get(`${server}/report/zipAcceptedDispersionZIP/${id}/${doc?.data?.result}`)
    setTimeout(() => {
      showZIP(url)
      setShowZipSTP('0')
    }, 10000);

    
  }

  const exportarZIPNominas = async (id: any) => {
    setShowZipNominas(id)
    
    const doc: any = await axios.get(`${server}/report/zipPayRoll/${id}`)
    const url: any =await axios.get(`${server}/report/zipPayRollGeneratorZIP/${id}`)
    setTimeout(() => {
      showZIP(url)
      setShowZipNominas('0')
    }, 20000);

    
  }

  return(
    <>
      {titlePlayRollNotRepeated?.map((month: any) =>(
        <div className="contenedorPrincipal">
          <div className="contenedorHeader">
              <div>
                <span className="tituloHeader-playroll">{month} {year}</span>
              </div>
            </div>
          <div>
            {allHistoric?.map((lis:any)=>
            moment(lis.init_date).format('MMMM') === month &&(
              <Accordion className="conteinerPlayRoll"
              style={{
                boxShadow: 'none'
              }}
              > 
                <div className="contenedorTabla">
                  <div className="contenedorTituloTablaValores">
                    <Grid container spacing={1}>
                      <Grid item xs={4} className="tituloTablasValores centrado ">
                        <div className="primerElementoTablaValores">
                          <AccordionSummary
                          aria-controls="panel2a-content"
                          id="panel2a-header" 
                          onClick={(e) => setIconoSemanal(!iconoSemanal)}>
                            
                            <img src={openBlok} 
                            alt="Nomina"
                            />
                          </AccordionSummary>
                        </div>
                        <div className="tableValuesPrimerElemento">
                          {esExtraordinaria(lis.Historic_name)? 
                          <span className="textotituloTabla-playroll"
                          style={{
                            fontWeight: 'bold',
                            color: 'rgb(9, 60, 93)'
                          }}
                          >{lis.Historic_name}</span>
                          :<span className="textotituloTabla-playroll">{lis.Historic_name}</span>
                          }
                          
                        </div>
                      </Grid>
                      <Grid item xs={4} className="tituloTablasValores centrado ">
                        <div className="tableValuesPrimerElemento"
                        style={{
                          width: '95%',
                          textAlign: 'center'
                        }}
                        >
                          <span className="textotituloTabla-playroll">Periódo {getDateMonthYear(lis.init_date)} {getDateMonth(lis.init_date)} - {getDateMonth(lis.end_date)}</span>
                        </div>
                      </Grid>
                      <Grid item xs={2} className="tituloTablasValores centrado">
                        <div className="tableValuesPrimerElemento">
                          <span className="textotituloTabla-playroll">Empleados: {lis.employees}</span>
                        </div>
                      </Grid>
                      <Grid item xs={2} className="tituloTablasValores centrado">
                        <div className="tableValuesPrimerElemento">
                          <span className="textotituloTabla-playroll">Total: {formatter(lis.total)}</span>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <AccordionDetails>
                  <div className="filaTablaValoresTop">
                    <Grid container spacing={3} className="contenedorTablaValores">
                      <Grid item xs={12} className="tituloTablasValores centrado ">
                        <Grid item xs={4}>
                            <div className="conteinerDetails-playroll">
                                <span className="textotituloTablaDetalle-playroll">Detalle de Nómina</span>
                            </div>
                        </Grid>   
                        <Grid item xs={4}>
                          {
                            CheckPermission("Nominas.Historicas.ReporteNomina") && (
                              <div className="conteinerDetails-playroll">
                                <div>
                                  <img className={styles.pc_iconoFlechaAmarilla} src='/assets/svg/icono-FlechaAmarilla.png' onClick={() => {
                                     setIdReportPayroll(lis.id)
                                     setTypeReportPayroll(lis.payroll_type)
                                  }}
                                  alt="Nomina"                              
                                  > 
                                  </img>
                                </div>
                                <div className="counteinderText-playroll">
                                  <span className="textoTablaDetalle-playroll">Reporte de Nómina</span>
                                </div>
                                    
                              </div>
                            )
                          }
                        </Grid>   
                        <Grid item xs={4}>
                          {
                            CheckPermission("Nominas.Historicas.ReciboNominaZIP") && (
                              <div className="conteinerDetails-playroll">
                                <div>
                                  {
                                    showZipNominas === '0' ?
                                      <a
                                        style={{ marginRight: "5px", marginLeft: "5px" }}
                                        target="_blank"
                                        //href={`${server}/report/zipPayRoll/${lis.id}`}
                                        onClick={() => {
                                          exportarZIPNominas(lis.id);
                                        }}
                                        rel="noopener noreferrer"
                                      >
                                        <img className={styles.pc_iconoFlechaAmarilla} src='/assets/svg/icono-FlechaAmarilla.png' 
                                        alt="Nomina"
                                        ></img>
                                      </a>
                                    :
                                      <img className={styles.pc_iconoFlechaAmarilla} src='/assets/svg/icono-FlechaAmarilla.png' 
                                      alt="Nomina"
                                      style={{
                                        cursor: 'wait'
                                      }}
                                      ></img>
                                  }
                                  
                                </div>
                                <div className="counteinderText-playroll">
                                  <span className="textoTablaDetalle-playroll"> Recibos de nómina ZIP</span>
                                </div>
                              </div>
                            )
                          }
                        </Grid>   
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className="contenedorTablaValores">
                      <Grid item xs={12} className="tituloTablasValores centrado ">
                        <Grid item xs={4}>
                          {
                            CheckPermission("Nominas.Historicas.DetalleNomina.Nomina") && (
                              <div className="conteinerDetails-playroll">
                                <div>
                                  {openPayroll(lis)}
                                  
                                  
                                </div>
                                <div className="counteinderText-playroll">
                                  <span className="textoTablaDetalle-playroll">Nómina</span>
                                </div>
                                      
                              </div>
                            )
                          }
                        </Grid>   
                        <Grid item xs={4}>
                          {
                            CheckPermission("Nominas.Histroricas.ReporePolizaContable") && (
                              <div className="conteinerDetails-playroll">
                                <div>
                                  <img className={styles.pc_iconoFlechaAmarilla} src='/assets/svg/icono-FlechaAmarilla.png'  onClick={() => handleOnExportListaRaya(lis.id)}
                                  alt="Nomina"
                                  > 
                                  </img>
                                </div>
                                <div className="counteinderText-playroll">
                                  <span className="textoTablaDetalle-playroll"> Reporte de Poliza Contable</span>
                                </div>
                              </div>
                            )
                          }
                        </Grid>   

                        <Grid item xs={4}>
                          {
                            CheckPermission("Nominas.Historicas.ReporteCEP") && (
                              <div className="conteinerDetails-playroll">
                                <div>
                                  <a
                                    style={{ marginRight: "5px", marginLeft: "5px" }}
                                    target="_blank"
                                    href="/#"
                                    onClick={(e) =>{
                                      e.preventDefault()
                                    }}
                                  >
                                    <img className={styles.pc_iconoFlechaAmarilla} src='/assets/svg/icono-FlechaAmarilla.png' onClick={() =>{
                                      setIdPayrolDispersar(lis.id)
                                      setTypePayrolDispersar(lis.payroll_type)
                                    } }
                                    alt="Nomina"
                                    ></img>
                                  </a>
                                </div>
                                <div className="counteinderText-playroll">
                                  <span className="textoTablaDetalle-playroll"> Reporte CEP</span>
                                </div>
                                  
                              </div>
                            )
                          }
                        </Grid>   
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className="contenedorTablaValores">
                      <Grid item xs={12} className="tituloTablasValores centrado ">
                        <Grid item xs={4}>
                            <div>
                                <span className=""></span>
                            </div>
                        </Grid>   
                        <Grid item xs={4}>
                          {
                            CheckPermission("Nominas.Historicas.ReporteSTP") && (
                              <div className="conteinerDetails-playroll">
                                <div>
                                  {
                                    showZipSTP === '0'?
                                      <a
                                      
                                      target="_blank"
                                      //href={`${server}/report/zipAcceptedDispersion/${lis.id}`}
                                      onClick={() => {
                                        exportarPDF(lis.id, lis.payroll_type);
                                      }}
                                      rel="noopener noreferrer"
                                      
                                      >
                                        <img className={styles.pc_iconoFlechaAmarilla} src='/assets/svg/icono-FlechaAmarilla.png' 
                                        alt="Nomina"
                                        ></img>
                                      </a>
                                    :
                                      <img className={styles.pc_iconoFlechaAmarilla} src='/assets/svg/icono-FlechaAmarilla.png' 
                                      style={{
                                        cursor:'wait'
                                      }}
                                      alt="Nomina"
                                      ></img>
                                  }
                                  
                                </div>
                                <div className="counteinderText-playroll">
                                  <span className="textoTablaDetalle-playroll"> Reporte de STP</span>
                                </div>
                              </div>
                            )
                          }
                          
                        </Grid>   
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className="contenedorTablaValores">
                      <div className="conteinerActionPayRoll">
                        <hr
                        style={{marginBottom: "4px"}}
                        />
                        <div className="actionPayroll">
                          <div className="actionCancel"
                          onClick={(()=>{
                            openDataCancel(lis?.id, lis?.payroll_type)
                          })}
                          >
                            <img
                              src={Cancel}
                              alt="Cancelar timbrado"
                            />
                            <p>Cancelar timbrado</p>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
        
        

      ))}
      {state.showDataCancelTimbre ? <DataCancelTimbre/>: null}
    </>
  )
}

export default HistoricPayrollTable